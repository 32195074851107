import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';

import LandingView from '../../components/LandingView';
import SetupView from '../../components/SetupView';
import { colors } from '../../constants/theme';
import { createPlayer, createTable } from '../../services/api';
import useSetRootCssProperty from '../../services/useSetRootCssProperty';
import { SnackbarsProvider } from '../../services/useSnackbars';
import useWindowSize from '../../services/useWindowSize';
import TablesContainer from '../TablesContainer';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: 'calc(var(--vh, 1vh) * 100)',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: colors.light,
    },
  };
});

const TABLES_ROUTE = '/tables';

function App() {
  const history = useHistory();

  const { height = 0 } = useWindowSize();
  const vh = height * 0.01;
  useSetRootCssProperty({
    '--vh': `${vh}px`,
  });

  const classes = useStyles();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState();
  const [userId, setUserId] = useState('');
  const [tableId, setTableId] = useState('');

  function handleAddPlayerSuccess(tableId: string) {
    history.push(`${TABLES_ROUTE}/${tableId}`);
  }

  function handleStart({
    playerName,
    small_blind,
    big_blind,
    starting_stack,
  }: {
    playerName: string;
    small_blind: number;
    big_blind: number;
    starting_stack: number;
  }) {
    return new Promise<void>((resolve, reject) => {
      setIsPending(true);
      return createTable({ small_blind, big_blind, starting_stack })
        .then(({ table_id }) =>
          createPlayer({ tableId: table_id, name: playerName })
        )
        .then((table) => {
          handleAddPlayerSuccess(table.table_id);
          setUserId(table.game.me.id);
          setTableId(table.table_id);
          setIsPending(false);
          resolve();
        })
        .catch((error) => {
          setError(error);
          setIsPending(false);
          reject();
        });
    });
  }

  return (
    <SnackbarsProvider>
      <Box className={classes.root}>
        <Switch>
          <Route exact path="/">
            <LandingView onStart={() => history.push('/start')} />
          </Route>
          <Route exact path="/start">
            <SetupView
              onSubmit={handleStart}
              isPending={isPending}
              error={error}
            />
          </Route>
          <Route path={TABLES_ROUTE}>
            <TablesContainer userId={userId} tableId={tableId} />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Box>
    </SnackbarsProvider>
  );
}

export default App;
