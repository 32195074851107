import React from 'react';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const propTypes = {
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
  error: PropTypes.object,
};

const defaultProps = {
  onSubmit: () => {},
  isPending: false,
  error: null,
};

const year = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function Footer({ className }) {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Typography variant="caption" component="p">
        Made with{' '}
        <span role="img" aria-label="love">
          ❤️
        </span>{' '}
        by{' '}
        <Link
          href="http://jpaulproductions.com/sound/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Jared
        </Link>{' '}
        and{' '}
        <Link
          href="http://github.com/lowellosaurus/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Lowell
        </Link>{' '}
        and{' '}
        <Link
          href="https://www.madisonbullard.org"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Madison
        </Link>
      </Typography>
      <Typography variant="caption" component="p">
        &copy; {year}
      </Typography>
    </Box>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
