import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import difference from 'lodash/difference';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import unionBy from 'lodash/unionBy';
import without from 'lodash/without';
import isEqual from 'react-fast-compare';

import { colors } from '../constants/theme';

const useStyles = makeStyles((theme) => ({
  snackBarWrapper: {
    top: 8,
    position: 'fixed',
    width: '100%',
    zIndex: 1400,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  snackbar: {
    position: 'relative',
    top: 'unset',
    left: 'unset',
    right: 'unset',
    transform: 'translateX(0%)',
  },
  alert: {
    position: 'relative',
    marginTop: 0,
    marginBottom: 8,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
  },
  success: {
    background: colors.secondary,
  },
  info: {
    background: colors.tertiary,
  },
}));

const SnackbarsContext = createContext();

export function SnackbarsProvider({ children, initialSnackbars = [] }) {
  const classes = useStyles();
  const [snackbars, setSnackbars] = useState(initialSnackbars);
  const [visibleSnackbars, setVisibleSnackbars] = useState(snackbars);

  useEffect(
    function handleAdd() {
      if (!isEqual(snackbars, visibleSnackbars)) {
        setVisibleSnackbars(snackbars);
      }
    },
    [snackbars, visibleSnackbars, visibleSnackbars.length]
  );

  useEffect(
    function handleVisibleRemove() {
      function handleRemoveVisible(snackbarsToRemove = []) {
        setTimeout(() => {
          setVisibleSnackbars(without(visibleSnackbars, ...snackbarsToRemove));
        }, 250);
      }
      if (visibleSnackbars.length > snackbars.length) {
        const diff = difference(visibleSnackbars, snackbars);
        handleRemoveVisible(diff);
      }
    },
    [visibleSnackbars, snackbars]
  );

  function handleClose(id) {
    const snackbar = find(snackbars, { id });
    setSnackbars(without(snackbars, snackbar));
  }

  return (
    <SnackbarsContext.Provider value={[snackbars, setSnackbars]}>
      {children}
      {!!visibleSnackbars.length && (
        <Box className={classes.snackBarWrapper}>
          {visibleSnackbars.map(
            ({ duration, icon, id, severity, message }, i) => (
              <Snackbar
                key={id}
                className={classes.snackbar}
                open={snackbars.map((s) => s.id).includes(id)}
                autoHideDuration={duration}
                onClose={() => handleClose(id)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                disableWindowBlurListener
              >
                <Alert
                  onClose={() => handleClose(id)}
                  className={classes.alert}
                  classes={{
                    standardSuccess: classes.success,
                    standardInfo: classes.info,
                  }}
                  icon={icon}
                  severity={severity}
                >
                  {message}
                </Alert>
              </Snackbar>
            )
          )}
        </Box>
      )}
    </SnackbarsContext.Provider>
  );
}

const SNACKBAR_DURATION = 5000;

export function useSnackbars() {
  const [snackbars, setSnackbars] = useContext(SnackbarsContext);
  if (typeof setSnackbars === undefined) {
    throw new Error('useSnackbars must be used within a SnackbarsProvider');
  }

  const handleSetSnackbars = useCallback(
    (newSnackbars = []) => {
      if (!isArray(newSnackbars)) {
        throw new Error('useSnackbars expects an array of snackbars');
      }
      const newSnackbarsWithDuration = newSnackbars.map((snackbar) => {
        const existingSnackbar = snackbars.find((s) => s.id === snackbar.id);
        return {
          ...snackbar,
          duration: !!existingSnackbar
            ? existingSnackbar.duration + 1
            : SNACKBAR_DURATION,
        };
      });
      const updatedSnackbars = unionBy(
        newSnackbarsWithDuration,
        snackbars,
        (s) => s.id
      );
      setSnackbars(updatedSnackbars);
    },
    [setSnackbars, snackbars]
  );

  return handleSetSnackbars;
}
