import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { colors } from '../../constants/theme';

const useStyles = makeStyles((theme) => {
  return {
    wager: {
      textTransform: 'uppercase',
      fontFamily: 'Roboto Condensed',
      lineHeight: 1,
    },
    allIn: {
      color: colors.warning,
    },
  };
});

function Wager({
  isAllIn = false,
  amount = 0,
}: {
  isAllIn: boolean;
  amount: number;
}) {
  const classes = useStyles();

  return (
    <Typography
      variant="h4"
      component="p"
      className={clsx(classes.wager, { [classes.allIn]: isAllIn })}
      data-testid="wager"
    >
      {amount.toLocaleString()}
    </Typography>
  );
}

export default Wager;
