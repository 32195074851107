import React, { useEffect, useCallback, useState } from 'react';

import localforage from 'localforage';
import find from 'lodash/find';
import without from 'lodash/without';
import zipObject from 'lodash/zipObject';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

import { TABLES_INDEX } from '../../constants';
import TableContainer from '../TableContainer';

const ACTION_TYPES = ['CREATE_TABLE_INDEX', 'REMOVE_TABLE_INDEX'];
const actionTypes = zipObject(ACTION_TYPES, ACTION_TYPES);

export type TTableIndex = { tableId: string; userId: string };

function getIndexWithNewTable(
  tableIndex: TTableIndex,
  state: TTableIndex[] | null
) {
  const { tableId, userId } = tableIndex;
  if (!!state && state.length) {
    const existingTableIndex = find(state, { tableId });
    if (!!existingTableIndex) {
      return [...without(state, existingTableIndex)!, { tableId, userId }];
    } else {
      return [...state, { tableId, userId }];
    }
  } else {
    return [{ tableId, userId }];
  }
}

function getUpdatedIndex(
  state: TTableIndex[] | null,
  action: { payload: TTableIndex; type: string }
) {
  switch (action.type) {
    case actionTypes.CREATE_TABLE_INDEX:
      return getIndexWithNewTable(action.payload, state);
    case actionTypes.REMOVE_TABLE_INDEX:
      const indexToRemove = find(state, { tableId: action.payload.tableId });
      return !!indexToRemove ? without(state, indexToRemove) : state;
    default:
      return state;
  }
}

function TablesContainer({
  userId = '',
  tableId = '',
}: {
  userId?: string;
  tableId?: string;
}) {
  const match = useRouteMatch();
  const history = useHistory();

  const [tablesIndex, setTablesIndex] = useState<TTableIndex[] | null>(null);
  const [hasCachedCreatedTable, setHasCachedCreatedTable] = useState(false);
  const [isAsyncActive, setIsAsyncActive] = useState(false);

  const handleTable = useCallback(
    (tableId, userId) => {
      const updatedIndex = getUpdatedIndex(tablesIndex, {
        type: actionTypes.CREATE_TABLE_INDEX,
        payload: {
          tableId,
          userId,
        },
      });

      return new Promise((resolve, reject) => {
        setIsAsyncActive(true);
        localforage
          .setItem(TABLES_INDEX, updatedIndex)
          .then((updatedIndex) => {
            setTablesIndex(updatedIndex);
            setIsAsyncActive(false);
            resolve(updatedIndex);
          })
          .catch(reject);
      });
    },
    [tablesIndex]
  );

  useEffect(
    function handleUserCreatedTable() {
      if (
        !!userId &&
        !!tableId &&
        !!tablesIndex &&
        !hasCachedCreatedTable &&
        !isAsyncActive
      ) {
        setHasCachedCreatedTable(true);
        handleTable(tableId, userId);
      }
    },
    [
      handleTable,
      hasCachedCreatedTable,
      isAsyncActive,
      tableId,
      tablesIndex,
      userId,
    ]
  );

  useEffect(
    function handleFetchCachedIndex() {
      if (!tablesIndex && !isAsyncActive) {
        setIsAsyncActive(true);
        localforage.getItem<TTableIndex[]>(TABLES_INDEX).then((state) => {
          setIsAsyncActive(false);
          setTablesIndex(state || []);
        });
      }
    },
    [isAsyncActive, tablesIndex]
  );

  const handleJoinTableSuccess = useCallback(
    (table) => {
      const tableId = table.table_id;
      const userId = table.game.me.id;

      return handleTable(tableId, userId).then(() => {
        history.push(`${match.url}/${tableId}`);
      });
    },
    [handleTable, history, match.url]
  );

  return (
    <Switch>
      <Route path={`${match.path}/:tableId`}>
        {tablesIndex ? (
          <TableContainer
            tablesIndex={tablesIndex}
            handleJoinTableSuccess={handleJoinTableSuccess}
            userId={userId}
          />
        ) : (
          <div>Loading...</div>
        )}
      </Route>
    </Switch>
  );
}

export default TablesContainer;
