import React, { ReactNode } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: 12,
    '&$isFirst': {
      marginLeft: 0,
      '& $tab:before': {
        left: -8,
        bottom: 0,
        height: 0,
        width: 0,
      },
    },
  },
  tab: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 22,
    minWidth: 48,
    padding: '0px 8px 0 8px',
    background: ({ backgroundColor }: { backgroundColor?: string }) =>
      backgroundColor || `#fff`,
    borderRadius: '4px 4px 0 0',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: -8,
      bottom: 0,
      height: 8,
      width: 8,
      borderBottomRightRadius: '50%',
      background: 'transparent',
      boxShadow: ({ backgroundColor }: { backgroundColor?: string }) =>
        `0 4px 0 0 ${backgroundColor || '#fff'}`,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: -20,
      bottom: 0,
      height: 20,
      width: 20,
      borderBottomLeftRadius: '50%',
      background: 'transparent',
      boxShadow: ({ backgroundColor }: { backgroundColor?: string }) =>
        `0 10px 0 0 ${backgroundColor || '#fff'}`,
    },
  },
  isFirst: {},
}));

function Tab({
  className,
  children,
  isFirst = false,
  backgroundColor,
}: {
  className?: string;
  children: ReactNode;
  isFirst?: boolean;
  backgroundColor?: string;
}) {
  const classes = useStyles({ backgroundColor });

  return (
    <Box className={clsx(classes.root, { [classes.isFirst]: isFirst })}>
      <Box className={clsx(classes.tab, className)}>{children}</Box>
    </Box>
  );
}

export default Tab;
