import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import * as yup from 'yup';

import LayoutOnboarding from '../LayoutOnboarding';
import SettingsFields, {
  TSettingsFormValues,
  validation,
} from '../SettingsFields';

const useStyles = makeStyles((theme) => {
  return {
    form: {
      width: '100%',
    },
    nameInput: {
      marginBottom: 24,
    },
    caption: {
      paddingTop: 16,
    },
  };
});

const maxPlayerNameLength = 28;

type TFormValues = {
  playerName: string;
} & TSettingsFormValues;

function SetupView({
  onSubmit = () => {},
  isPending = false,
  error = '',
}: {
  onSubmit: (values: Required<TFormValues>) => void;
  isPending: boolean;
  error?: string;
}) {
  const classes = useStyles();

  const validationSchema = yup.object().shape({
    playerName: yup
      .string()
      .max(maxPlayerNameLength, 'Needs to be less than 28 characters 😅')
      .required('Hold up...what’s your name?')
      .defined(),
    ...validation,
  });

  function handleSubmit(values: Required<TFormValues>) {
    const castValues = validationSchema.cast(values);
    if (castValues) {
      const { playerName, big_blind, small_blind, starting_stack } = castValues;
      return onSubmit({
        playerName: playerName!,
        big_blind: big_blind!,
        small_blind: small_blind!,
        starting_stack: starting_stack!,
      });
    }
  }

  return (
    <Formik<Required<TFormValues>>
      onSubmit={handleSubmit}
      initialValues={{
        playerName: '',
        small_blind: 10,
        big_blind: 20,
        starting_stack: 1200,
      }}
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <LayoutOnboarding>
            <Box
              component="form"
              onSubmit={
                handleSubmit as (e: React.FormEvent<HTMLElement>) => void
              }
              className={classes.form}
            >
              <TextField
                className={classes.nameInput}
                label="Your player name"
                name="playerName"
                value={values.playerName}
                fullWidth
                inputProps={{
                  maxLength: maxPlayerNameLength,
                }}
                disabled={isPending}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.playerName && !!errors.playerName}
                helperText={touched.playerName ? errors.playerName : ''}
                autoFocus={true}
              />
              <SettingsFields
                onChange={handleChange}
                onBlur={handleBlur}
                isDisabled={isPending}
                errors={errors}
                touched={touched}
                values={values}
                settingsConfig={['STARTING_STACK', 'BLINDS']}
              />
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                type="submit"
                size="large"
                disabled={isPending}
                disableElevation
              >
                Next
              </Button>
              {!!error && (
                <Typography
                  color="error"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  Yeaahhh...something is not working quite right, sorry.{' '}
                  <span role="img" aria-label="sad face">
                    😔
                  </span>{' '}
                  Maybe try again?
                </Typography>
              )}
            </Box>
          </LayoutOnboarding>
        );
      }}
    </Formik>
  );
}

export default SetupView;
