import React, { useContext } from 'react';

import { Theme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import getSidePots from '../../services/getSidePots';
import { useSnackbars } from '../../services/useSnackbars';
import KeyValDisplay from '../KeyValDisplay';

const { sidePots: sidePotBadgeColors } = colors;

const Pots = observer(() => {
  const classes = useStyles();
  const tableStore = useContext(TableContext)!;
  const table = tableStore.table!;
  const sidePots = getSidePots(table.game.side_pot);
  return (
    <>
      <KeyValDisplay
        isNumeric
        label={!!sidePots?.length ? 'Main pot' : 'Pot'}
        className={clsx(classes.mainPot, {
          [classes.hasSidepots]: !!sidePots.length,
        })}
        value={table.game.pot.toLocaleString() || 0}
      />
      {!!sidePots.length && (
        <KeyValDisplay
          isNumeric
          label={sidePots.length > 1 ? 'Side pots' : 'Side pot'}
          className={classes.sidePotSection}
        >
          {sidePots.map((sidePot, i) => (
            <Box key={i} className={classes.sidePot} pt={0.5}>
              <Avatar
                className={classes.avatar}
                style={{
                  borderColor:
                    sidePotBadgeColors[i % sidePotBadgeColors.length],
                  color: sidePotBadgeColors[i % sidePotBadgeColors.length],
                }}
              >
                {i + 1}
              </Avatar>
              <Typography variant="body2" component="p" data-testid="sidePot">
                {sidePot.pot.toLocaleString()}
              </Typography>
            </Box>
          ))}
        </KeyValDisplay>
      )}
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    left: {
      display: 'flex',
      '& > *': {
        marginRight: 8,
      },
    },
    blindsWrapper: {
      userSelect: 'none',
      display: 'flex',
      justifyContent: 'flex-start',
      marginRight: 0,
      alignItems: 'center',
      cursor: 'pointer',
      '& $editBlindsBtn': {
        transition: 'margin-left 0.15s ease',
      },
      '&:hover $editBlindsBtn': {
        marginLeft: -4,
      },
      '&:hover $blinds': {
        filter: 'brightness(102%)',
      },
    },
    blinds: {
      position: 'relative',
      zIndex: 1,
      transition: 'filter 0.2s ease',
    },
    editBlindsBtn: {
      transition: 'margin-left 0.2s ease',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      position: 'relative',
      zIndex: 0,
      paddingLeft: 8,
      marginLeft: -6,
      paddingRight: 2,
      paddingTop: 8,
      paddingBottom: 8,
      minWidth: 'inherit',
      boxShadow: theme.shadows[1],
      background: colors.grayLightColor,
      color: colors.grayMidColor,
      borderRadius: '0 4px 4px 0',
      '&$blindsUpdatePending': {
        transition: 'background-color 0.3s ease',
        paddingRight: 1,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 7,
        background: colors.grayLight,
      },
    },
    editBlindsIcon: {
      fontSize: '0.875rem',
    },
    blindsUpdatePending: {
      border: `1px solid ${colors.secondary}`,
      '& $editBlindsIcon': {
        color: colors.secondary,
      },
    },
    mainPot: {
      '&$hasSidepots': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginRight: 0,
      },
    },
    hasSidepots: {},
    sidePotSection: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      paddingLeft: 0,
    },
    sidePot: {
      display: 'flex',
      padding: 0,
      paddingRight: 4,
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    avatar: {
      fontSize: '0.875rem',
      height: 16,
      width: 16,
      marginRight: 4,
      background: 'transparent',
      border: '1px solid',
    },
    menuBtn: {
      marginLeft: 8,
      background: colors.grayLight,
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
      color: colors.grayMidColor,
      transition: 'filter 0.2s ease',
      '&:hover': {
        filter: 'brightness(102%)',
        background: colors.grayLight,
      },
    },
  };
});

const MenuBar = observer(
  ({
    className,
    handleOpenBlindsMenu,
    handleOpenSettingsMenu,
  }: {
    className?: string;
    handleOpenBlindsMenu: () => void;
    handleOpenSettingsMenu: () => void;
  }) => {
    const classes = useStyles();
    const setSnackbars = useSnackbars();

    const tableStore = useContext(TableContext)!;
    const table = tableStore.table!;
    const bigBlind = table.game.current_hand_big_blind_value;
    const smallBlind = table.game.current_hand_small_blind_value;
    const nextGameBigBlind = table.settings.big_blind;
    const nextGameSmallBlind = table.settings.small_blind;
    const isBlindsUpdatePending =
      bigBlind !== nextGameBigBlind || smallBlind !== nextGameSmallBlind;
    const isAdmin = table.admin_id === table.game.me.id;

    return (
      <Box className={clsx(classes.root, className)}>
        <Box className={classes.left}>
          <Pots />
          <Box
            className={classes.blindsWrapper}
            onClick={
              isAdmin
                ? handleOpenBlindsMenu
                : () =>
                    setSnackbars([
                      {
                        id: 'CANNOT_EDIT_BLINDS',
                        severity: 'success',
                        message: `Only the admin (${tableStore.adminName}) can change blinds.`,
                        icon: '🦮',
                      },
                    ])
            }
          >
            <KeyValDisplay
              label="Blinds"
              value={`${smallBlind.toLocaleString()}/${bigBlind.toLocaleString()}`}
              isNumeric
              className={classes.blinds}
            />
            <Box
              className={clsx(
                classes.editBlindsBtn,
                isBlindsUpdatePending && classes.blindsUpdatePending
              )}
            >
              <EditIcon className={classes.editBlindsIcon} />
            </Box>
          </Box>
        </Box>
        <IconButton
          aria-label="menu"
          className={classes.menuBtn}
          onClick={handleOpenSettingsMenu}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  }
);

export default MenuBar;
