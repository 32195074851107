import TableStore from '../stores/TableStore';

export default function getPlayerContext(
  playerId: string,
  tableStore: TableStore
) {
  const table = tableStore.table!;
  const player = tableStore.players![playerId];
  const isDealer = playerId === table.game.dealer_id;
  return {
    hasFolded: player.status === 'FOLDED',
    hasDealAction: isDealer && table.game.status === 'COMPLETE',
    isAdmin: playerId === table.admin_id,
    isAllIn: ['IDLE', 'BETTING'].includes(player.status) && player.stack <= 0,
    isBetting: player.status === 'BETTING',
    isDealer,
    isRetired: player.status === 'RETIRED',
    isInactive: player.status === 'INACTIVE',
  };
}
