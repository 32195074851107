import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import map from 'lodash/map';
import { observer } from 'mobx-react-lite';
import isEqual from 'react-fast-compare';

import { TableContext } from '../../contexts/TableContext';
import { TCard } from '../../interfaces';

const useStyles = makeStyles((theme) => ({
  revealCardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  row: {
    marginBottom: 8,
    flex: '0 1 75%',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  revealCardButton: {
    fontWeight: 'bold',
    marginRight: 8,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
}));

function getCardString(card: TCard) {
  const suitMap = {
    hearts: '♥️',
    spades: '♠️',
    clubs: '♣️',
    diamonds: '♦️',
  };

  return `${card.rank.toUpperCase()}${suitMap[card.suit]}`;
}

const RevealCardButtons = observer(
  ({
    className,
    isPending = false,
    playerId,
    revealedCards,
  }: {
    className?: string;
    isPending?: boolean;
    playerId: string;
    revealedCards: Record<number, TCard | undefined>;
  }) => {
    const classes = useStyles();
    const tableStore = useContext(TableContext)!;
    const userHand = tableStore.userHand!;

    const buttonProps: ButtonProps = {
      fullWidth: true,
      disableElevation: true,
      disabled: isPending,
      className: classes.row,
    };

    return (
      <Box className={clsx(classes.row, classes.revealCardWrapper, className)}>
        {map(userHand, (card, i) => {
          const isButtonDisabled = Object.values(
            revealedCards
          ).some((revealedCard) => isEqual(revealedCard, card));

          return (
            <Button
              {...buttonProps}
              data-testid={`revealCardBtn${i}`}
              key={`${card?.suit}${card?.rank}`}
              className={clsx(classes.revealCardButton)}
              variant="outlined"
              color="primary"
              onClick={() => tableStore.revealCards([Number(i)])}
              disabled={isButtonDisabled}
            >
              {`${isButtonDisabled ? 'Revealed' : 'Reveal'} ${getCardString(
                card!
              )}`}
            </Button>
          );
        })}
      </Box>
    );
  }
);

export default RevealCardButtons;
