import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      '&:hover $label': {
        visibility: 'initial',
      },
    },
    chipLeaderIcon: {
      color: colors.gold,
      fontSize: '0.8rem',
      marginBottom: 2,
      marginLeft: 2,
    },
    stack: {
      color: colors.grayMid,
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Roboto Condensed',
      fontSize: '1rem',
      maxWidth: 88,
    },
    label: {
      marginLeft: 4,
      color: colors.grayLight,
      fontSize: '0.6rem',
      visibility: 'hidden',
    },
    allIn: {
      color: colors.warning,
    },
  };
});

const Stack = observer(
  ({
    isAllIn = false,
    className,
    stack,
  }: {
    isAllIn?: boolean;
    className?: string;
    stack: number;
  }) => {
    const classes = useStyles();
    const tableStore = useContext(TableContext)!;
    const largestStack = tableStore.largestStack;
    const isLargestStack = stack === largestStack;

    return (
      <Box className={classes.root}>
        <Typography
          component="p"
          className={clsx(
            classes.stack,
            { [classes.allIn]: isAllIn },
            className
          )}
          data-testid="stack"
        >
          {stack?.toLocaleString()}
          <Grow in={isLargestStack}>
            <StarIcon className={classes.chipLeaderIcon} />
          </Grow>
        </Typography>
        <Typography variant="overline" className={classes.label}>
          stack
        </Typography>
      </Box>
    );
  }
);

export default Stack;
