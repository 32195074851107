import localforage from 'localforage';

import { LOCALFORAGE_ID } from '../constants';

export function configureLocalforage() {
  localforage.config({
    name: LOCALFORAGE_ID,
    storeName: LOCALFORAGE_ID,
    driver: [
      localforage.WEBSQL,
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
    ],
  });
}
