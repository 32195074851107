import React, { useMemo, useCallback } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flatMap from 'lodash/flatMap';
import head from 'lodash/head';
import max from 'lodash/max';
import tail from 'lodash/tail';

import { ReactComponent as ChipIconTen } from '../../assets/chips/10chip.svg';
import { ReactComponent as ChipIconOne } from '../../assets/chips/1chip.svg';
import { ReactComponent as ChipIconTwo } from '../../assets/chips/2chip.svg';
import { ReactComponent as ChipIconThree } from '../../assets/chips/3chip.svg';
import { ReactComponent as ChipIconFour } from '../../assets/chips/4chip.svg';
import { ReactComponent as ChipIconFive } from '../../assets/chips/5chip.svg';
import { ReactComponent as ChipIconSix } from '../../assets/chips/6chip.svg';
import { ReactComponent as ChipIconSeven } from '../../assets/chips/7chip.svg';
import { ReactComponent as ChipIconEight } from '../../assets/chips/8chip.svg';
import { ReactComponent as ChipIconNine } from '../../assets/chips/9chip.svg';
import { colors } from '../../constants/theme';

const chipIconIndex = [
  ChipIconOne,
  ChipIconTwo,
  ChipIconThree,
  ChipIconFour,
  ChipIconFive,
  ChipIconSix,
  ChipIconSeven,
  ChipIconEight,
  ChipIconNine,
  ChipIconTen,
];

type TChipSize = 'small' | 'medium' | 'large' | 'xl';

function getWidth(size: TChipSize) {
  switch (size) {
    case 'small':
    default:
      return 12;
    case 'medium':
      return 16;
    case 'large':
      return 24;
    case 'xl':
      return 32;
  }
}

type TChipId = 'chip0' | 'chip1' | 'chip2' | 'chip3' | 'chip4';
function getChipId(index: number): TChipId {
  return `chip${index.toString()}` as TChipId;
}

const chipColors: { id: TChipId; color: string }[] = [
  {
    id: 'chip0',
    color: '#F0FBFF',
  },
  {
    id: 'chip1',
    color: '#C70005',
  },
  {
    id: 'chip2',
    color: '#2732BB',
  },
  {
    id: 'chip3',
    color: '#73C892',
  },
  {
    id: 'chip4',
    color: colors.grayDarkColor,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  chip: {
    userSelect: 'none',
    marginBottom: ({ size }: { size: TChipSize }) =>
      -getWidth(size) / 13.33333333333,
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: ({ size }: { size: TChipSize }) => Math.ceil(getWidth(size)),
    marginRight: ({ size }: { size: TChipSize }) =>
      -Math.ceil(getWidth(size) / 2.4),
    marginTop: 'auto',
    '&:first-of-type': {
      marginRight: 0,
    },
    '&:nth-of-type(odd)': {
      bottom: 0,
      zIndex: 2,
    },
    '&:nth-of-type(even)': {
      bottom: ({ size }: { size: TChipSize }) => Math.ceil(getWidth(size) / 6),
      zIndex: 1,
    },
  },
  isHand: {
    '&:not(:first-child)': {
      marginLeft: ({ size }: { size: TChipSize }) => -getWidth(size) * 0.5,
    },
  },
  rowReverse: {
    flexDirection: 'row-reverse',
  },
}));

function getUniqueChipLimit({
  amount,
  chipMultiples,
  smallestValue,
}: {
  amount: number;
  chipMultiples: number[];
  smallestValue: number;
}) {
  const highestMult = max(chipMultiples)!;
  if (amount > smallestValue * highestMult * 4) {
    return chipMultiples.length || 1;
  } else if (amount > smallestValue * highestMult * 2) {
    return max([chipMultiples.length - 1, 1]);
  } else if (amount > (smallestValue * highestMult) / 2) {
    return max([chipMultiples.length - 2, 1]);
  } else {
    return 1;
  }
}

function getChipCountsByChipValues(amount: number, chipValues: number[]) {
  function go(
    amount: number,
    values: number[],
    chipCounts: number[] = []
  ): number[] {
    if (!values.length || !amount) {
      return chipCounts;
    } else {
      const chipValue = head(values)!;
      const maxAmount =
        values.length === 1
          ? amount
          : Math.floor((amount / values.length) * 1.4);
      const chipCount = Math.floor(maxAmount / chipValue);

      return go(amount - chipCount * chipValue, tail(values), [
        ...chipCounts,
        chipCount,
      ]);
    }
  }

  return go(amount, chipValues);
}

function Stack({
  className,
  count,
  chipId,
  size,
  style,
  ...otherProps
}: {
  className?: string;
  count: number;
  chipId: TChipId;
  size: TChipSize;
  style?: React.CSSProperties;
}) {
  const classes = useStyles({ size });
  const ChipIcon = chipIconIndex[count - 1];
  return (
    <Box className={clsx(classes.stack, `stack${count}`, className)}>
      <ChipIcon
        className={clsx(classes.chip)}
        {...otherProps}
        style={{
          ...style,
          color: chipColors.find((chip) => chip.id === chipId)?.color,
          width: getWidth(size),
          height: 'auto',
        }}
      />
    </Box>
  );
}

function Stacks({
  chipCount,
  stackHeight,
  size,
  chipIndex,
}: {
  chipCount: number;
  stackHeight: number;
  size: TChipSize;
  chipIndex: number;
}) {
  function getStackCount(chipCount: number, i: number) {
    const remaining = chipCount - stackHeight * i;
    return remaining >= stackHeight ? stackHeight : remaining;
  }
  return (
    <>
      {Array(Math.ceil(chipCount / stackHeight))
        .fill(null)
        .map((_, i) => {
          return (
            <Stack
              key={i}
              count={getStackCount(chipCount, i)}
              size={size}
              chipId={getChipId(chipIndex)}
            />
          );
        })}
    </>
  );
}

function Chips({
  amount,
  chipMultiples,
  size,
  smallestValue,
  stackHeight,
}: {
  amount: number;
  chipMultiples: number[];
  size: TChipSize;
  smallestValue: number;
  stackHeight: number;
}) {
  const uniqueChipLimit = useMemo(
    () =>
      getUniqueChipLimit({
        amount,
        chipMultiples,
        smallestValue,
      }),
    [amount, chipMultiples, smallestValue]
  );

  const chipValues = useMemo(
    () =>
      chipMultiples
        .map((mult) => smallestValue * mult)
        .sort((a, b) => a - b)
        .filter((_, i) => i < uniqueChipLimit!)
        .reverse(),
    [chipMultiples, smallestValue, uniqueChipLimit]
  );

  const chipCountsByValue = getChipCountsByChipValues(amount, chipValues);
  return (
    <>
      {flatMap([...chipCountsByValue.reverse()], (chipCount, i) => (
        <Stacks
          key={`${chipCount}r${i}`}
          chipCount={chipCount}
          stackHeight={stackHeight}
          size={size}
          chipIndex={i}
        />
      ))}
    </>
  );
}

export type TChipsAnimationProps = {
  amount: number;
  direction?: 'left' | 'right';
  size: TChipSize;
  className: string;
};

function ChipsAnimation({
  amount,
  direction = 'right',
  size,
  className,
}: {
  amount: number;
  direction?: 'left' | 'right';
  size: TChipSize;
  className: string;
}) {
  const classes = useStyles({ size });
  const stackHeight = 10;

  const ChipsComponent = useCallback(
    () => (
      <Chips
        amount={amount}
        chipMultiples={[1, 2, 10, 20, 50]}
        size={size}
        smallestValue={10}
        stackHeight={stackHeight}
      />
    ),
    [amount, size]
  );

  return (
    <Box
      className={clsx(classes.root, className, {
        [classes.rowReverse]: direction === 'left',
      })}
    >
      <ChipsComponent />
    </Box>
  );
}

export default ChipsAnimation;
