import { useState, useEffect, useRef } from 'react';

export default function useDragScroll() {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [lastMousePosition, setLastMousePosition] = useState<number | null>(
    null
  );
  const ref = useRef<HTMLElement | null>(null);

  function onMouseDown(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    setIsMouseDown(true);
    setLastMousePosition(e.clientX);
  }

  useEffect(() => {
    function onMouseUp() {
      setIsMouseDown(false);
      setLastMousePosition(null);
    }
    function onMouseMove(e: MouseEvent) {
      if (!isMouseDown) return;
      if (ref.current === null) return;
      if (lastMousePosition === null) return;

      ref.current.scrollLeft += lastMousePosition! - e.clientX;
      setLastMousePosition(e.clientX);
    }

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [isMouseDown, lastMousePosition]);

  return {
    ref,
    onMouseDown,
  };
}
