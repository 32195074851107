import React, { useContext } from 'react';

import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import EmoteBackground from '../EmoteBackground';



const useStyles = makeStyles((theme: Theme) => {
  return {
    user: {
      border: `1px solid ${colors.secondary}`,
    },
    paper: {
      width: '100%',
      marginLeft: 4,
      marginBottom: 4,
      minHeight: 24,
      padding: 8,
      overflow: 'hidden',
      position: 'relative',
      zIndex: 1,
      background: colors.grayLight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        flex: '1 1 148px',
      },
    },
    emoteBackground: {
      width: '100%',
      height: '100%',
    },
    paperLeft: {
      display: 'flex',
      alignItems: 'center',
      height: 24,
    },
    paperRight: {
      display: 'flex',
      alignItems: 'center',
    },
    stack: {
      marginRight: 8,
      fontFamily: 'Roboto Condensed',
      color: colors.grayMid,
    },
  };
});

const PlayerCompact = observer(
  ({ id, className }: { id: string; className?: string }) => {
    const classes = useStyles();
    const tableStore = useContext(TableContext)!;
    const player = tableStore.players![id];
    const userId = tableStore.table!.game.me.id;

    return (
      <Paper
        key={player.id}
        elevation={0}
        className={clsx(
          classes.paper,
          {
            [classes.user]: player.id === userId,
          },
          className
        )}
        data-testid={`playerCompact_${player.name}`}
      >
        <Box className={classes.paperLeft}>{player.name}</Box>
        <Box className={classes.paperRight}>
          <Typography variant="body2" className={classes.stack}>
            {player.stack || 0}
          </Typography>
        </Box>
        <EmoteBackground person={player} className={classes.emoteBackground} />
      </Paper>
    );
  }
);

export default PlayerCompact;
