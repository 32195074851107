import React from 'react';

import Button from '@material-ui/core/Button';

import PlayerList from '../PlayerList';

function EditPlayersMenu({
  isPending,
  onClose,
  buttonText,
}: {
  isPending: boolean;
  onClose: () => void;
  buttonText: string;
}) {
  return (
    <>
      <PlayerList isPending={isPending} />
      <Button onClick={onClose} fullWidth disabled={isPending}>
        {buttonText}
      </Button>
    </>
  );
}

export default EditPlayersMenu;
