import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { TableContext } from '../../contexts/TableContext';
import useDragScroll from '../../services/useDragScroll';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      height: 21,
    },
    emotesWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    emote: {
      padding: '0 8px',
      fontSize: '0.75rem',
      lineHeight: 2,
      borderRadius: theme.shape.borderRadius,
    },
  };
});

const emotes = [
  '😂',
  '😭',
  '😎',
  '🤬',
  '🤯',
  '😴',
  '👀',
  '🙊',
  '🍻',
  '🎯',
  '💅🏽',
  '🐞',
  '🔥',
  '💯',
  '🎷',
  '🥜',
  '🆒',
  '🃏',
];

function EmoteMenu({ className, isOpen, close }) {
  const classes = useStyles();
  const tableStore = useContext(TableContext);
  const dragProps = useDragScroll();

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent={false}
      onClickAway={() => isOpen && close()}
    >
      <Box className={clsx(classes.root, className)} {...dragProps}>
        <Box className={classes.emotesWrapper}>
          {emotes.map((emote) => (
            <ButtonBase
              key={emote}
              onClick={() => {
                tableStore.emote(emote);
              }}
              className={classes.emote}
            >
              {emote}
            </ButtonBase>
          ))}
        </Box>
      </Box>
    </ClickAwayListener>
  );
}

export default EmoteMenu;
