import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

import { ButtonProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import endsWith from 'lodash/endsWith';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import { IPlayer } from '../../interfaces';

const useStyles = makeStyles((theme) => ({
  rowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  row: {
    marginBottom: 8,
    flex: '0 1 75%',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  confirmFoldHeading: {
    height: 36,
    textAlign: 'center',
  },
  confirmDangerButton: {
    background: colors.warning,
    '&:hover': {
      background: colors.warningHover,
    },
  },
  chipButton: {
    width: '25%',
    fontWeight: 'bold',
    paddingRight: 4,
    paddingLeft: 4,
    marginRight: 4,
    marginBottom: 8,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  allInButton: {
    width: 69,
    borderColor: colors.warning,
    color: colors.warning,
    '&:hover': {
      background: colors.warningOutlineHover,
    },
    flex: '0 1 25%',
    paddingRight: 0,
    paddingLeft: 0,
    marginRight: 4,
  },
  raiseBtn: {
    flex: '0 0 75%',
    marginBottom: 8,
  },
  checkboxFormControl: {
    borderRadius: 4,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    padding: 0,
    background: 'transparent',
    border: `1px dashed ${colors.grayLightColor}`,
    width: '100%',
    height: 36,
    margin: 0,
    marginBottom: 8,
    '&$isCheckboxEnabled': {
      color: colors.secondary,
      borderStyle: 'solid',
      borderColor: colors.secondary,
      background: `${colors.secondary}22`,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  checkboxFormControl_disabled: {
    color: colors.grayLightColor,
    '& $checkbox': {
      color: `${colors.grayLightColor}77`,
    },
    borderColor: `${colors.grayLightColor}77`,
  },
  checkboxFormControl_label: {
    color: colors.primary,
    fontSize: '0.875rem',
    paddingLeft: 4,
  },
  checkbox: {
    color: colors.grayLightColor,
  },
  isCheckboxEnabled: {},
}));

const HandActions = observer(
  ({
    areActionsDisabled,
    autoCallAmount,
    callAmount,
    canMeetWager,
    hasDealAction,
    isAutoCheckEnabled,
    isAutoFoldEnabled,
    isBetting,
    isCheck,
    isPending,
    onDeal,
    onFold,
    pendingBet,
    setAutoCallAmount,
    setIsAutoCheckEnabled,
    setIsAutoFoldEnabled,
    setPendingBet,
    user,
  }: {
    areActionsDisabled: boolean;
    autoCallAmount: number;
    callAmount: number;
    canMeetWager: boolean;
    hasDealAction: boolean;
    isAutoCheckEnabled: boolean;
    isAutoFoldEnabled: boolean;
    isBetting: boolean;
    isCheck: boolean;
    isPending: boolean;
    onDeal: () => void;
    onFold: () => void;
    pendingBet: number;
    setAutoCallAmount: (amount: number) => void;
    setIsAutoCheckEnabled: (isEnabling: boolean) => void;
    setIsAutoFoldEnabled: (isEnabling: boolean) => void;
    setPendingBet: Dispatch<SetStateAction<number>>;
    user: IPlayer;
  }) => {
    const classes = useStyles();
    const tableStore = useContext(TableContext)!;
    const maxStackAndWager = tableStore.maxStackAndWager!;
    const bigBlind = tableStore.table!.settings.big_blind;
    const chipValues = [1, 5, 10, 25].map((m) => m * bigBlind);
    const [isAllInPending, setIsAllInPending] = useState(false);
    const [isFoldPending, setIsFoldPending] = useState(false);
    const [isRaisePending, setIsRaisePending] = useState(false);
    const wager = user.wager;
    const stack = user.stack;

    const canGoAllIn = maxStackAndWager >= stack + wager;
    const largestPossibleBet = canGoAllIn ? stack : maxStackAndWager - wager;
    const availableToBet = largestPossibleBet - pendingBet;

    useEffect(() => {
      setIsRaisePending(false);
      setPendingBet(0);
      setIsFoldPending(false);
      setIsAllInPending(false);
    }, [areActionsDisabled, setPendingBet, hasDealAction, isBetting]);

    const buttonProps: ButtonProps = {
      fullWidth: true,
      disableElevation: true,
      disabled: areActionsDisabled || isPending,
      className: classes.row,
    };

    const chipButtonProps: ButtonProps = {
      ...buttonProps,
      fullWidth: false,
      variant: 'outlined',
      color: 'primary',
      className: classes.chipButton,
    };

    function getChipString(val: number) {
      if (val >= 1000 && endsWith(val.toString(), '00')) {
        return `${val / 1000}k`;
      } else if (val) {
        return val.toLocaleString();
      }
    }

    function getAllInStringSuffix() {
      if (!callAmount) {
        return `(bet ${largestPossibleBet.toLocaleString()})`;
      } else if (largestPossibleBet > callAmount) {
        return `(+${largestPossibleBet.toLocaleString()})`;
      } else {
        return `(${!stack ? 'call' : largestPossibleBet.toLocaleString()})`;
      }
    }

    function getCallString() {
      if (callAmount === stack && stack > 0) {
        return `All in ${getAllInStringSuffix()}`;
      } else if (callAmount > 0) {
        return `+${callAmount.toLocaleString()} (Call)`;
      } else {
        return 'Call';
      }
    }

    function getRaiseString() {
      if (canMeetWager && stack >= bigBlind && stack > callAmount) {
        if (!callAmount) {
          return 'Bet';
        } else {
          return 'Raise';
        }
      } else {
        return `All in ${getAllInStringSuffix()}`;
      }
    }

    function handleRaise(raiseIncrement: number) {
      const canIncrement = availableToBet - raiseIncrement >= 0;
      const isCallSatisfied = pendingBet >= callAmount;
      if (canIncrement && isCallSatisfied) {
        setPendingBet(pendingBet + raiseIncrement);
      } else if (canIncrement && !isCallSatisfied) {
        setPendingBet(callAmount + raiseIncrement);
      } else {
        setPendingBet(stack);
      }
    }

    if (hasDealAction) {
      return (
        <>
          <Button
            {...buttonProps}
            variant="contained"
            onClick={onDeal}
            color="secondary"
          >
            Deal next hand
          </Button>
        </>
      );
    } else if (!isBetting) {
      return (
        <>
          <FormControlLabel
            className={clsx(classes.checkboxFormControl, {
              [classes.isCheckboxEnabled]: !!autoCallAmount,
            })}
            classes={{
              label: classes.checkboxFormControl_label,
              disabled: classes.checkboxFormControl_disabled,
            }}
            disabled={!callAmount}
            onChange={() =>
              setAutoCallAmount(!!autoCallAmount ? 0 : callAmount)
            }
            control={
              <Checkbox
                className={classes.checkbox}
                size="small"
                inputProps={{
                  'aria-label': 'Call checkbox',
                }}
                checked={!!autoCallAmount}
              />
            }
            label={
              <Typography variant="button">
                Call +{callAmount.toLocaleString()}
              </Typography>
            }
          />
          <FormControlLabel
            className={clsx(classes.checkboxFormControl, {
              [classes.isCheckboxEnabled]: isAutoCheckEnabled,
            })}
            classes={{
              label: classes.checkboxFormControl_label,
              disabled: classes.checkboxFormControl_disabled,
            }}
            onChange={() => setIsAutoCheckEnabled(!isAutoCheckEnabled)}
            control={
              <Checkbox
                className={classes.checkbox}
                size="small"
                inputProps={{
                  'aria-label': 'Check checkbox',
                }}
                checked={isAutoCheckEnabled}
              />
            }
            label={<Typography variant="button">Check</Typography>}
          />
          <FormControlLabel
            className={clsx(classes.checkboxFormControl, {
              [classes.isCheckboxEnabled]: isAutoFoldEnabled,
            })}
            classes={{
              label: classes.checkboxFormControl_label,
              disabled: classes.checkboxFormControl_disabled,
            }}
            onChange={() => setIsAutoFoldEnabled(!isAutoFoldEnabled)}
            control={
              <Checkbox
                className={classes.checkbox}
                size="small"
                inputProps={{
                  'aria-label': 'Fold checkbox',
                }}
                checked={isAutoFoldEnabled}
              />
            }
            label={<Typography variant="button">Fold any bet</Typography>}
          />
        </>
      );
    } else if (isAllInPending) {
      return (
        <>
          <Box className={classes.row}>
            <Typography
              variant="h6"
              component="h4"
              className={classes.confirmFoldHeading}
            >
              Are you sure?
            </Typography>
          </Box>
          <Button
            {...buttonProps}
            className={clsx(buttonProps.className, classes.confirmDangerButton)}
            variant="contained"
            onClick={() => tableStore.bet(largestPossibleBet)}
          >
            {canGoAllIn
              ? `Confirm all in ${getAllInStringSuffix()}`
              : `Put 'em all in ${getAllInStringSuffix()}`}
          </Button>
          <Button
            {...buttonProps}
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsAllInPending(false);
              setPendingBet(0);
            }}
          >
            Cancel
          </Button>
        </>
      );
    } else if (isFoldPending) {
      return (
        <>
          <Box className={classes.row}>
            <Typography
              variant="h6"
              component="h4"
              className={classes.confirmFoldHeading}
            >
              Are you sure?
            </Typography>
          </Box>
          <Button
            {...buttonProps}
            className={clsx(buttonProps.className, classes.confirmDangerButton)}
            variant="contained"
            onClick={onFold}
          >
            Confirm fold
          </Button>
          <Button
            {...buttonProps}
            variant="outlined"
            color="primary"
            onClick={() => setIsFoldPending(false)}
          >
            Cancel
          </Button>
        </>
      );
    } else if (isRaisePending) {
      return (
        <>
          <Box className={classes.rowWrapper}>
            {chipValues.map((val) => (
              <Button
                key={val}
                {...chipButtonProps}
                onClick={() => {
                  handleRaise(val);
                }}
                disabled={availableToBet - val < 0}
              >{`+${getChipString(val)}`}</Button>
            ))}
          </Box>
          <Box className={classes.rowWrapper}>
            <Button
              {...buttonProps}
              className={clsx(buttonProps.className, classes.allInButton)}
              variant="outlined"
              onClick={() => setIsAllInPending(true)}
            >
              {canGoAllIn ? 'All in' : 'Max bet'}
            </Button>
            <Button
              {...buttonProps}
              className={clsx(classes.raiseBtn)}
              disabled={buttonProps.disabled || !pendingBet}
              variant="contained"
              color="secondary"
              onClick={() =>
                pendingBet === stack
                  ? setIsAllInPending(true)
                  : tableStore.bet(pendingBet)
              }
            >
              {!pendingBet
                ? 'Raise'
                : `Raise ${(pendingBet - callAmount).toLocaleString()}`}
            </Button>
          </Box>
          <Button
            {...buttonProps}
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsRaisePending(false);
              setPendingBet(0);
            }}
          >
            Cancel
          </Button>
        </>
      );
    } else {
      return (
        <>
          {canMeetWager && !(callAmount >= stack && stack > 0) && (
            <Button
              {...buttonProps}
              variant="contained"
              color="primary"
              onClick={() => tableStore.bet(callAmount)}
            >
              {isCheck ? 'Check' : getCallString()}
            </Button>
          )}
          <Button
            {...buttonProps}
            variant="contained"
            color="secondary"
            onClick={() =>
              callAmount === stack && stack > 0
                ? tableStore.bet(stack)
                : setIsRaisePending(true)
            }
          >
            {getRaiseString()}
          </Button>
          <Button
            {...buttonProps}
            variant="outlined"
            color="primary"
            onClick={() => setIsFoldPending(true)}
          >
            Fold
          </Button>
        </>
      );
    }
  }
);

export default HandActions;
