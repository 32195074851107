import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  useContext,
  createContext,
  ReactNode,
} from 'react';

import localforage from 'localforage';
import isNumber from 'lodash/isNumber';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import isEqual from 'react-fast-compare';
import useSound from 'use-sound';

import { TableContext } from '../contexts/TableContext';
import { ETableEvent } from '../interfaces';

const allIn = require('../assets/sfx/allIn.mp3').default;
const bet = require('../assets/sfx/bet.mp3').default;
const callFoley = require('../assets/sfx/call_foley.mp3').default;
const callTone = require('../assets/sfx/call_tone.mp3').default;
const check = require('../assets/sfx/check.mp3').default;
const deal = require('../assets/sfx/deal.mp3').default;
// const endGameLose = require('../assets/sfx/end_game_lose.mp3').default;
// const endGameWin = require('../assets/sfx/end_game_win.mp3').default;
const endHand = require('../assets/sfx/end_hand.mp3').default;
const fold = require('../assets/sfx/fold.mp3').default;
const hurryUp = require('../assets/sfx/hurry_up.mp3').default;
const join = require('../assets/sfx/join.mp3').default;
const leave = require('../assets/sfx/leave.mp3').default;
const loseHand = require('../assets/sfx/lose_hand.mp3').default;
const raise1 = require('../assets/sfx/raise1.mp3').default;
const raise2 = require('../assets/sfx/raise2.mp3').default;
const raise3 = require('../assets/sfx/raise3.mp3').default;
const winHand = require('../assets/sfx/win_hand.mp3').default;
const yourTurn = require('../assets/sfx/your_turn.mp3').default;

const SfxContext = createContext<
  | {
      isMuted: boolean;
      setIsMuted: (isMuted: boolean) => void;
      setVolume: (volume: number) => void;
      volume: number;
    }
  | undefined
>(undefined);

const volumeKey = 'VOLUME';

export function useSfx() {
  const context = useContext(SfxContext);
  if (!context) {
    throw new Error('useSfx must be used within a SfxContextProvider');
  }
  const { isMuted, setIsMuted, setVolume, volume } = context;

  return {
    isMuted,
    setIsMuted,
    setVolume,
    volume,
  };
}

export const SfxContextProvider = observer(
  ({ children }: { children: ReactNode }) => {
    const [volume, setVolume] = useState(1);
    const unmuteVolumeRef = useRef(volume);
    const [isFetchingVolume, setIsFetchingVolume] = useState(false);
    const [hasFetchedVolume, setHasFetchedVolume] = useState(false);
    const [callPlaybackRate, setCallPlaybackRate] = useState(1);
    const [raiseEventCount, setRaiseEventCount] = useState(0);

    function handleSetVolume(volume: number) {
      setVolume(volume);
      localforage.setItem(volumeKey, volume);
    }
    const cachedHandleSetVolume = useCallback(handleSetVolume, []);

    useEffect(
      function handleSetVolumeOnMount() {
        if (!isFetchingVolume && !hasFetchedVolume) {
          setIsFetchingVolume(true);
          localforage.getItem(volumeKey).then((volume) => {
            if (isNumber(volume)) {
              setVolume(volume);
            } else {
              cachedHandleSetVolume(0.7);
            }
            setHasFetchedVolume(true);
          });
        }
      },
      [cachedHandleSetVolume, hasFetchedVolume, isFetchingVolume]
    );

    const tableStore = useContext(TableContext)!;
    const table = toJS(tableStore.table);
    const tableRef = useRef(table);

    const players = tableStore.players!;

    const user = players[table!.game.me.id];
    const userStatus = user?.status || 'INACTIVE';
    const userId = user?.id;
    const dealerId = tableStore.table!.game.dealer_id;
    const isDealer = userId === dealerId;

    const useSoundOpts = { volume, soundEnabled: !!volume };
    const [playAllIn] = useSound(allIn, useSoundOpts);
    const [playBet] = useSound(bet, useSoundOpts);
    const [playCallFoley] = useSound(callFoley, useSoundOpts);
    const [playCallTone] = useSound(callTone, {
      ...useSoundOpts,
      playbackRate: callPlaybackRate,
    });
    const [playCheck] = useSound(check, useSoundOpts);
    const [playDeal] = useSound(deal, useSoundOpts);
    const [playFold] = useSound(fold, useSoundOpts);
    const [playRaise1] = useSound(raise1, useSoundOpts);
    const [playRaise2] = useSound(raise2, useSoundOpts);
    const [playRaise3] = useSound(raise3, useSoundOpts);
    // const [playWin] = useSound(endGameWin, useSoundOpts);
    // const [playLose] = useSound(endGameLose, useSoundOpts);
    const [playYourTurn] = useSound(yourTurn, useSoundOpts);
    const [
      playHurryUp,
      { stop: stopHurryUp, isPlaying: isPlayingHurryUp },
    ] = useSound(hurryUp, useSoundOpts);
    const [playJoin] = useSound(join, useSoundOpts);
    const [playLeave] = useSound(leave, useSoundOpts);
    const [playWinHand] = useSound(winHand, useSoundOpts);
    const [playLoseHand] = useSound(loseHand, useSoundOpts);
    const [playEndHand] = useSound(endHand, useSoundOpts);

    function playCall() {
      playCallFoley();
      playCallTone();
      setCallPlaybackRate(callPlaybackRate * 1.0594881348);
    }

    function getPlayMatchComplete() {
      // const user = players[userId] || spectators[userId];
      // XXX: FIX IT LATER
      return;
      // if (players.filter((p) => !!p.stack).length === 1 && !!user?.stack) {
      //   return playWin;
      // } else if (!user?.stack && isPlayerActive) {
      //   return playLose;
      // } else {
      //   // TODO: Return neutral game complete
      //   return null;
      // }
    }

    function getPlayRaise() {
      switch (raiseEventCount) {
        case 0:
        default:
          return playRaise1;
        case 1:
          return playRaise2;
        case 2:
          return playRaise3;
      }
    }

    function getPlayHandler(eventType: ETableEvent | undefined) {
      switch (eventType) {
        case 'ALL_IN':
          return playAllIn;
        case 'BET':
          return playBet;
        case 'CALL':
          return playCall;
        case 'CHECK':
          return playCheck;
        case 'DEAL':
          return playDeal;
        case 'FOLD':
          return playFold;
        case 'GAME_COMPLETE':
          return getPlayMatchComplete();
        case 'LEAVE':
          return playLeave;
        case 'JOIN':
          return playJoin;
        case 'RAISE':
          return getPlayRaise();
        case 'YOUR_TURN':
          return playYourTurn;
        default:
          return null;
      }
    }

    function handleToggleMute(isMuted: boolean) {
      if (isMuted) {
        unmuteVolumeRef.current = volume;
        handleSetVolume(0);
      } else {
        handleSetVolume(Math.max(unmuteVolumeRef.current, 0.3));
      }
    }

    const play = getPlayHandler(table?.last_event);
    const cachedPlay = useMemo(() => play, [play]);

    useEffect(
      function handleTablesUpdate() {
        const play = cachedPlay;
        if (!!table && !isEqual(tableRef.current, table)) {
          if (
            (userStatus === 'BETTING' && table.game.status === 'ACTIVE') ||
            (isDealer && table.game.status === 'COMPLETE')
          ) {
            if (!isPlayingHurryUp) {
              playHurryUp();
            }
          } else {
            stopHurryUp();
          }

          if (table.game.hand_id !== tableRef.current?.game.hand_id) {
            setCallPlaybackRate(1);
          }

          if (
            table.game.community_cards.length !==
            tableRef.current?.game.community_cards.length
          ) {
            setRaiseEventCount(0);
          }

          if (table.last_event !== 'EMOTE') {
            if (
              !!table.game.victors.length &&
              !tableRef.current?.game.victors.length
            ) {
              if (!!table.game.victors.find((v) => v.id === userId)) {
                playWinHand();
              } else if (['IDLE', 'BETTING'].includes(userStatus)) {
                playLoseHand();
              } else {
                playEndHand();
              }
            }

            if (!!play) {
              play();
              if (table.last_event === 'RAISE') {
                setRaiseEventCount(raiseEventCount + 1);
              }
            }
          }

          tableRef.current = table;
        }
      },
      [
        cachedPlay,
        isDealer,
        isPlayingHurryUp,
        playEndHand,
        playHurryUp,
        playLoseHand,
        playWinHand,
        raiseEventCount,
        stopHurryUp,
        table,
        userId,
        userStatus,
      ]
    );

    return (
      <SfxContext.Provider
        value={{
          isMuted: !volume,
          setIsMuted: handleToggleMute,
          setVolume: cachedHandleSetVolume,
          volume,
        }}
      >
        {children}
      </SfxContext.Provider>
    );
  }
);
