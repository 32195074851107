import React, { createContext, ReactNode, useContext } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    paddingBottom: 8,
    '&:last-of-type': {
      paddingBottom: 0,
    },
  },
}));

function Wrapper({
  children,
  classes: passedClasses,
  ...props
}: {
  children: ReactNode;
  classes?: typeof useStyles;
}) {
  const classes = useStyles();
  return (
    <ListItem
      classes={{
        gutters: classes.listItem,
        ...(passedClasses || {}),
      }}
      {...props}
    >
      {children}
    </ListItem>
  );
}

type TRenderPropChildren = (arg: { ListItem: typeof Wrapper }) => any;
type TGameListProps = {
  className?: string;
  children: TRenderPropChildren | ReactNode;
};

const gameListContextValue = {
  ListItem: Wrapper,
};
function GameList({ className, children, ...otherProps }: TGameListProps) {
  const classes = useStyles();

  return (
    <GameListContext.Provider value={gameListContextValue}>
      <List className={clsx(classes.root, className)} {...otherProps}>
        {isFunction(children) ? children({ ListItem: Wrapper }) : children}
      </List>
    </GameListContext.Provider>
  );
}

export default GameList;

const GameListContext = createContext<
  | {
      ListItem: typeof Wrapper;
    }
  | undefined
>(undefined);

export function useGameList() {
  const context = useContext(GameListContext);
  if (!context) {
    throw new Error(
      'useGameList must be used within a GameListContextProvider'
    );
  }

  const { ListItem } = context;

  return {
    ListItem,
  };
}
