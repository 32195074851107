import React from 'react';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { colors } from '../../constants/theme';
import { EHandCategory } from '../../interfaces';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    fontSize: '0.6rem',
    height: 16,
    border: `1px solid ${colors.gold}`,
    background: '#FDFACA',
  },
}));

function getCategoryString(category: EHandCategory) {
  switch (category) {
    case 'LAST_STANDING':
      return 'Last standing';
    case 'HIGH_CARD':
      return 'High card';
    case 'ONE_PAIR':
      return 'One pair';
    case 'TWO_PAIR':
      return 'Two pair';
    case 'THREE_OF_A_KIND':
      return 'Three of a kind';
    case 'STRAIGHT':
      return 'Straight';
    case 'FLUSH':
      return 'Flush';
    case 'FULL_HOUSE':
      return 'Full house';
    case 'FOUR_OF_A_KIND':
      return 'Four of a kind';
    case 'STRAIGHT_FLUSH':
      return 'Straight flush';
  }
}

function HandCategory({
  className,
  category,
}: {
  className?: string;
  category: EHandCategory;
}) {
  const classes = useStyles();

  return (
    <Chip
      size="medium"
      className={clsx(classes.wrapper, className)}
      data-testid="handCategory"
      label={getCategoryString(category)}
    />
  );
}

export default HandCategory;
