import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';

import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grow from '@material-ui/core/Grow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import EmoteIcon from '@material-ui/icons/EmojiEmotions';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSTransition } from 'react-transition-group';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import getPlayerContext from '../../services/getPlayerContext';
import useInterval from '../../services/useInterval';
import AdminTag from '../AdminTag';
import Cards from '../Cards';
import ChipsAnimation from '../ChipsAnimation';
import EmoteBackground from '../EmoteBackground';
import EmoteMenu from '../EmoteMenu';
import HandActions from '../HandActions';
import HandCategory from '../HandCategory';
import HandTopElements from '../HandTopElements';
import RevealCardButtons from '../RevealCardButtons';
import Stack from '../Stack';
import Wager from '../Wager';

const TRANSITION_DURATION = 100;

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      width: '100%',
    },
    wrapper: {
      width: '100%',
      position: 'relative',
      '&$folded': {
        width: 'calc(100% - 16px)',
        marginLeft: 'auto',
        color: colors.grayMid,
      },
      '&$retired': {
        width: 'calc(100% - 16px)',
        marginLeft: 'auto',
      },
      '&$emoteMenuOpen': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        background: colors.grayLight,
      },
    },
    paper: {
      width: '100%',
      minHeight: 24,
      padding: 8,
      overflow: 'hidden',
      position: 'relative',
      borderTopLeftRadius: 0,
      zIndex: 1,
      transition: 'background-color 0.4s ease-in-out',
      '&$betting': {
        border: `1px solid ${colors.secondary}`,
        padding: 7,
      },
      '&$folded': {
        background: colors.grayLight,
        color: colors.grayMid,
        '& $cards': {
          filter: 'saturate(0.5) contrast(0.5) brightness(115%)',
        },
      },
      '&$retired': {
        background: colors.grayLight,
        color: colors.grayMid,
      },
      '&$emoteMenuOpen': {
        borderTopLeftRadius: theme.shape.borderRadius,
      },
      '&$victor': {
        border: `1px solid ${colors.gold}`,
        padding: 7,
        background: `linear-gradient(
          156deg, 
          rgba(252,246,186,1) 9%, 
          rgba(247,236,172,1) 18%, 
          rgba(247,232,148,1) 20%, 
          rgba(255,235,120,1) 32%, 
          rgba(246,231,85,1) 39%, 
          rgba(233,212,77,1) 48%, 
          rgba(255,238,163,1) 61%, 
          rgba(251,245,183,1) 66%, 
          rgba(245,232,118,1) 79%, 
          rgba(241,235,177,1) 97%
        )`,
        '&:after': {
          animation: '$shine 5s ease-in-out infinite',
          animationFillMode: 'forwards',
          content: '""',
          position: 'absolute',
          top: '-110%',
          left: '-210%',
          width: '100%',
          height: '400%',
          opacity: 0,
          background: `linear-gradient(
              to right,
              rgba(255,255,255,0) 0%,
              rgba(255,255,255,0.13) 77%,
              rgba(255,255,255,0.5) 92%, 
              rgba(255,255,255,0.0) 100%
            )`,
          transform: 'rotate(30deg)',
          pointerEvents: 'none',
        },
      },
    },
    '@keyframes shine': {
      '10%': {
        opacity: '1',
        top: '-160%',
        left: '30%',
        transitionProperty: 'left,top,opacity',
        transitionDuration: '0.9s,0.9s,0.12s',
        transitionTimingFunction: 'ease',
      },
      '100%': {
        opacity: '0',
        top: '-160%',
        left: '30%',
        transitionProperty: 'left,top,opacity',
      },
    },
    betting: {},
    victor: {},
    emoteMenuOpen: {},
    emoteMenu: {
      width: 'calc(100% - 20px)',
      '&$emoteMenuOpen:before': {
        content: '""',
        left: 0,
        width: 16,
        height: 21,
        zIndex: 3,
        borderTopLeftRadius: theme.shape.borderRadius,
        position: 'absolute',
        background: `linear-gradient(-90deg, ${colors.grayLight}00 0%, ${colors.grayLight}FF 100%)`,
      },
    },
    topElements: {
      width: 'calc(100% - 20px)',
    },
    emoteMenuToggle: {
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 3,
      color: colors.grayMidColor,
      '&:hover': {
        color: colors.grayDarkColor,
      },
      '&:active': {
        color: colors.dark,
      },
      borderRadius: 12,
      '&:before': {
        content: '""',
        left: -16,
        pointerEvents: 'none',
        position: 'absolute',
        background: `linear-gradient(90deg, ${colors.light}00 0%, ${colors.light}FF 100%)`,
        width: 16,
        height: 21,
        zIndex: 3,
      },
      '&$emoteMenuOpen': {
        '&:before': {
          transition: `background-color ${TRANSITION_DURATION}ms`,
          background: `linear-gradient(90deg, ${colors.grayLight}00 0%, ${colors.grayLight}FF 100%)`,
        },
      },
      '& > *': {
        position: 'relative',
        zIndex: 2,
      },
    },
    bettingBar: {
      zIndex: 2,
      position: 'relative',
      width: '100%',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      border: `1px solid ${colors.secondary}`,
      borderBottom: 0,
      marginTop: -4,
    },
    folded: {},
    retired: {},
    handInfo: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    left: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      position: 'relative',
    },
    emoteBackground: {
      width: '100%',
      height: '100%',
    },
    main: {
      width: '100%',
      position: 'relative',
    },
    stack: {
      zIndex: 2,
    },
    wagerWrapper: {
      display: 'flex',
      alignItems: 'center',
      zIndex: 2,
    },
    totalBetPaper: {
      position: 'relative',
      zIndex: 1,
      marginLeft: 8,
      padding: 4,
      display: 'flex',
      height: '100%',
      background: `${colors.white}CC`,
      border: `1px dashed ${colors.grayLightColor}`,
      color: colors.grayLightColor,
    },
    actionsWrapper: {
      marginTop: 16,
    },
    solidBorder: {
      border: `1px solid ${colors.secondary}`,
      color: colors.secondary,
    },
    totalBet: {
      lineHeight: 1,
      fontFamily: 'Roboto Condensed',
    },
    chipsAnimation: {
      marginBottom: 4,
      width: 0,
      filter: `drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
        drop-shadow(0 3px 1px rgba(0, 0, 0, 0.07))
        drop-shadow(0 1px 5px rgba(0, 0, 0, 0.06))
        drop-shadow(0 -1px 2px rgba(0, 0, 0, 0.05))`,
    },
    cardsWrapper: {
      marginLeft: 8,
      position: 'relative',
      flexShrink: 0,
      width: 132,
      textAlign: 'right',
    },
    handCategory: {
      marginBottom: 8,
    },
    cards: {},
    nonTurnActions: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    marginTop: {
      marginTop: 8,
    },

    // transitions
    topElementsEnter: {
      transform: 'translateY(21px)',
    },
    topElementsEnterActive: {
      transform: 'translateY(0)',
      transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(.38,.06,.76,.9)`,
      zIndex: 0,
    },
    topElementsExit: {
      transform: 'translateY(0)',
    },
    topElementsExitActive: {
      transform: 'translateY(21px)',
      transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(.38,.06,.76,.9)`,
      zIndex: 0,
      position: 'absolute',
      top: 0,
    },

    emotesEnter: {
      transform: 'translateY(-12px)',
      opacity: 0,
    },
    emotesEnterActive: {
      transform: 'translateY(0)',
      opacity: 1,
      transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(.38,.06,.76,.9), opacity ${TRANSITION_DURATION}ms`,
      zIndex: 0,
    },
    emotesExit: {
      transform: 'translateY(0)',
      opacity: 1,
    },
    emotesExitActive: {
      transform: 'translateY(-12px)',
      opacity: 0,
      transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(.38,.06,.76,.9), opacity ${TRANSITION_DURATION}ms`,
      zIndex: 0,
      position: 'absolute',
      top: 0,
    },
  };
});

type TUserHand = {
  className?: string;
  isPending?: boolean;
};

const UserHand = observer(({ className, isPending = false }: TUserHand) => {
  const classes = useStyles();
  const tableStore = useContext(TableContext)!;
  const table = tableStore.table!;
  const user = tableStore.players![table.game.me.id]!;
  const victorIds = tableStore.victorIds;
  const victorHandCategory = tableStore.victors?.[user.id]?.category;
  const name = user.name;
  const stack = user.stack;
  const wager = user.wager;
  const bet = tableStore.bet;
  const onBet = useCallback((amount: number) => bet(amount), [bet]);
  const onDeal = tableStore.deal;
  const onFold = tableStore.fold;
  const isGameActive = table.game.status === 'ACTIVE';
  const hand = tableStore.userHand!;
  const revealedCards = tableStore.hands![user.id];
  const callAmount = tableStore.largestWager! - wager;
  const isCheck = callAmount <= 0;
  const canMeetWager = stack - callAmount >= 0;

  const {
    hasDealAction,
    hasFolded,
    isAdmin,
    isAllIn,
    isBetting,
    isRetired,
    isInactive,
  } = getPlayerContext(user.id, tableStore);

  const [isEmoteMenuOpen, setIsEmoteMenuOpen] = useState(false);
  const [isAutoCheckEnabled, setIsAutoCheckEnabled] = useState(false);
  const [isAutoFoldEnabled, setIsAutoFoldEnabled] = useState(false);
  const [autoCallAmount, setAutoCallAmount] = useState(0);

  const areActionsDisabled =
    (!isGameActive || isInactive || hasFolded || isRetired) && !hasDealAction;

  function handleSetAutoCall(amount: number) {
    setAutoCallAmount(amount);
    if (!!amount) {
      setIsAutoFoldEnabled(false);
    }
  }

  function handleSetAutoFold(isEnabling: boolean) {
    setIsAutoFoldEnabled(isEnabling);
    if (!!autoCallAmount) {
      setAutoCallAmount(0);
    }
  }

  useEffect(
    function handleAutoCall() {
      if (
        isBetting &&
        autoCallAmount &&
        autoCallAmount >= callAmount &&
        !isPending
      ) {
        setAutoCallAmount(0);
        onBet(callAmount);
      }
    },
    [autoCallAmount, callAmount, isBetting, isPending, onBet]
  );

  useEffect(
    function handleAutoCheck() {
      if (isBetting && isAutoCheckEnabled && isCheck && !isPending) {
        setIsAutoCheckEnabled(false);
        onBet(callAmount);
      }
    },
    [callAmount, isAutoCheckEnabled, isBetting, isCheck, isPending, onBet]
  );

  useEffect(
    function handleAutoFold() {
      if (isBetting && isAutoFoldEnabled && !!callAmount && !isPending) {
        setIsAutoFoldEnabled(false);
        onFold();
      }
    },
    [callAmount, isAutoFoldEnabled, isBetting, isPending, onFold]
  );

  useEffect(
    function handleResetAutoActions() {
      if (!isGameActive) {
        setIsAutoCheckEnabled(false);
        setIsAutoFoldEnabled(false);
        setAutoCallAmount(0);
      }
    },
    [isGameActive]
  );

  useEffect(
    function handleResetAutoCall() {
      if (!callAmount) {
        setAutoCallAmount(0);
      }
    },
    [callAmount]
  );

  const [pendingBet, setPendingBet] = useState(0);
  const [betAmount, setBetAmount] = useState(pendingBet);
  const pendingBetRef = useRef(0);
  const increment = useRef(0);

  useEffect(
    function initializeBetAnimation() {
      if (pendingBet !== pendingBetRef.current) {
        if (!!pendingBet) {
          const newIncrement = (pendingBet - pendingBetRef.current) / 6;
          pendingBetRef.current = pendingBet;
          increment.current = newIncrement;
        } else {
          setBetAmount(0);
          pendingBetRef.current = 0;
          increment.current = 0;
        }
      }
    },
    [pendingBet]
  );

  useInterval(function animateBetAmount() {
    if (!!pendingBet && pendingBet !== betAmount) {
      const newRaiseAmount = Math.round(betAmount + increment.current);
      if (newRaiseAmount < callAmount) {
        setBetAmount(callAmount);
      } else if (newRaiseAmount > pendingBet) {
        setBetAmount(pendingBet);
      } else {
        setBetAmount(newRaiseAmount);
      }
    }
  }, 40);

  const areHandActionsVisible =
    (isGameActive && !isRetired && !hasFolded && !isInactive) || hasDealAction;

  const areRevealCardsBtnsVisible = !isGameActive && !!hand;

  return (
    <Box className={clsx(classes.root, className)} data-testid="userHand">
      <Box
        className={clsx(
          classes.wrapper,
          { [classes.betting]: isGameActive && isBetting },
          { [classes.folded]: hasFolded },
          { [classes.retired]: isRetired || isInactive },
          { [classes.emoteMenuOpen]: isEmoteMenuOpen }
        )}
      >
        <CSSTransition
          in={isEmoteMenuOpen}
          timeout={TRANSITION_DURATION}
          classNames={{
            enter: classes.emotesEnter,
            enterActive: classes.emotesEnterActive,
            exit: classes.emotesExit,
            exitActive: classes.emotesExitActive,
          }}
          unmountOnExit
        >
          <EmoteMenu
            className={clsx(classes.emoteMenu, {
              [classes.emoteMenuOpen]: isEmoteMenuOpen,
            })}
            isOpen={isEmoteMenuOpen}
            close={() => setIsEmoteMenuOpen(false)}
          />
        </CSSTransition>
        <CSSTransition
          in={!isEmoteMenuOpen}
          timeout={TRANSITION_DURATION}
          classNames={{
            enter: classes.topElementsEnter,
            enterActive: classes.topElementsEnterActive,
            exit: classes.topElementsExit,
            exitActive: classes.topElementsExitActive,
          }}
          unmountOnExit
        >
          <HandTopElements
            className={classes.topElements}
            {...{
              player: user,
              isGameActive,
            }}
          />
        </CSSTransition>
        <ButtonBase
          className={clsx(classes.emoteMenuToggle, {
            [classes.emoteMenuOpen]: isEmoteMenuOpen,
          })}
          onClick={() => setIsEmoteMenuOpen(!isEmoteMenuOpen)}
        >
          {isEmoteMenuOpen ? (
            <CloseIcon fontSize="small" />
          ) : (
            <EmoteIcon fontSize="small" />
          )}
        </ButtonBase>
        <Paper
          elevation={isBetting && isGameActive ? 2 : 1}
          className={clsx(
            classes.paper,
            { [classes.betting]: isGameActive && isBetting },
            { [classes.folded]: hasFolded },
            { [classes.retired]: isRetired || isInactive },
            { [classes.victor]: victorIds?.includes(user.id) },
            { [classes.emoteMenuOpen]: isEmoteMenuOpen }
          )}
          data-testid={`playerHand_${name}`}
        >
          <Box className={classes.handInfo}>
            <Box className={classes.left} data-testid="playerLabels">
              {isAdmin && <AdminTag />}
              {!isRetired && !isInactive && (
                <>
                  <Box className={classes.main}>
                    <Stack
                      isAllIn={isAllIn}
                      stack={stack}
                      className={classes.stack}
                    />
                    {!hasFolded && (
                      <Box className={classes.wagerWrapper}>
                        <Wager amount={wager} isAllIn={isAllIn} />
                        <Grow
                          in={!!pendingBet || !!callAmount}
                          style={{ transformOrigin: 'bottom center' }}
                        >
                          <Paper
                            className={clsx(classes.totalBetPaper, {
                              [classes.solidBorder]:
                                !!betAmount || autoCallAmount === callAmount,
                            })}
                            variant="outlined"
                          >
                            <Typography
                              variant="body2"
                              component="p"
                              className={classes.totalBet}
                            >
                              +{(betAmount || callAmount).toLocaleString()}
                            </Typography>
                          </Paper>
                        </Grow>
                      </Box>
                    )}
                  </Box>
                </>
              )}
              <EmoteBackground
                person={user}
                className={classes.emoteBackground}
              />
            </Box>
            {isGameActive && !hasFolded && (
              <ChipsAnimation
                amount={pendingBet || wager}
                className={classes.chipsAnimation}
                direction="left"
                size="large"
              />
            )}
            {!isInactive && !isRetired && (
              <Box className={classes.cardsWrapper}>
                {victorHandCategory && (
                  <HandCategory
                    category={victorHandCategory}
                    className={classes.handCategory}
                  />
                )}
                <Cards
                  playerId={user.id}
                  size="large"
                  className={classes.cards}
                />
              </Box>
            )}
          </Box>
          {(areHandActionsVisible || areRevealCardsBtnsVisible) && (
            <Box
              className={clsx(classes.actionsWrapper, {
                [classes.nonTurnActions]: !isBetting,
              })}
            >
              {areHandActionsVisible && (
                <HandActions
                  onDeal={onDeal}
                  onFold={onFold}
                  setAutoCallAmount={handleSetAutoCall}
                  setIsAutoFoldEnabled={handleSetAutoFold}
                  user={user}
                  {...{
                    areActionsDisabled,
                    autoCallAmount,
                    callAmount,
                    canMeetWager,
                    isAutoCheckEnabled,
                    isAutoFoldEnabled,
                    isBetting,
                    isCheck,
                    isPending,
                    hasDealAction,
                    pendingBet,
                    setIsAutoCheckEnabled,
                    setPendingBet,
                    stack,
                  }}
                />
              )}
              {areRevealCardsBtnsVisible && (
                <RevealCardButtons
                  revealedCards={revealedCards}
                  className={clsx({
                    [classes.marginTop]: hasDealAction,
                  })}
                  playerId={user.id}
                  {...{
                    isPending,
                  }}
                />
              )}
            </Box>
          )}
        </Paper>
        {isBetting && isGameActive && (
          <LinearProgress
            variant="query"
            color="secondary"
            className={classes.bettingBar}
          />
        )}
      </Box>
    </Box>
  );
});

export default UserHand;
