import React, { useState, useRef, useLayoutEffect, CSSProperties } from 'react';

import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { compareDesc, add, parseISO } from 'date-fns';
import keyBy from 'lodash/keyBy';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { CSSTransition } from 'react-transition-group';

import { TEmote, IPerson } from '../../interfaces';

const EMOTE_DURATION = 4000;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      position: 'absolute',
    },
    emoteAppear: {
      opacity: 1,
    },
    emoteAppearDone: {
      opacity: 0,
      transition: `opacity ${EMOTE_DURATION}ms`,
    },
  };
});

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

function getIsEmoteActive(emote: TEmote) {
  if (!emote || !emote.time) {
    return false;
  }
  const now = new Date();
  return (
    compareDesc(
      now,
      add(parseISO(emote.time), { seconds: EMOTE_DURATION / 1000 })
    ) > 0
  );
}

function EmoteBackground({
  className,
  person,
}: {
  className?: string;
  person: IPerson;
}) {
  const classes = useStyles();
  const divRef = useRef<HTMLDivElement>(null);
  const {
    // @ts-ignore window.Cypress ref doesnt populate in Cypress :shrug:
    width = window.Cypress ? 200 : 0,
    // @ts-ignore window.Cypress ref doesnt populate in Cypress :shrug:
    height = window.Cypress ? 200 : 0,
  } = divRef.current?.getBoundingClientRect() || {};

  return (
    <div
      ref={divRef}
      data-testid="emoteBackground"
      className={clsx(classes.root, className)}
    >
      <InnerEmoteBackground {...{ person, width, height }} />
    </div>
  );
}

const InnerEmoteBackground = observer(
  ({
    person,
    width,
    height,
  }: {
    person: IPerson;
    width: number;
    height: number;
  }) => {
    const classes = useStyles();
    const emote = person.emote;
    const emoteTime = emote?.time;

    const [emotesById, setEmotes] = useState<
      Record<string, TEmote & { style: CSSProperties }>
    >({});

    useLayoutEffect(() => {
      if (
        !!emote &&
        !!emoteTime &&
        !emotesById[emoteTime] &&
        getIsEmoteActive(emote) &&
        !!height &&
        !!width
      ) {
        autorun(() => {
          setEmotes((emotesById) => ({
            [emoteTime]: {
              ...emote,
              style: {
                position: 'absolute',
                left: `${getRandomInt(8, width - 16)}px`,
                top: `${getRandomInt(0, height - 32)}px`,
                fontSize: `${getRandomArbitrary(1.2, 3)}rem`,
                transform: `rotate(${getRandomArbitrary(0, 90) - 45}deg)`,
              },
            },
            ...keyBy(
              Object.values(emotesById).filter(getIsEmoteActive),
              'time'
            ),
          }));
        });
      }
    }, [emote, emoteTime, emotesById, height, width]);

    return (
      <>
        {Object.values(emotesById).map(({ message, time, style }, i) => {
          return (
            <CSSTransition
              in={true}
              appear={true}
              timeout={10}
              classNames={{
                appear: classes.emoteAppear,
                appearDone: classes.emoteAppearDone,
              }}
              unmountOnExit
              key={time}
            >
              <span style={{ ...style, zIndex: -i }}>{message}</span>
            </CSSTransition>
          );
        })}
      </>
    );
  }
);

export default EmoteBackground;
