import assignWith from 'lodash/assignWith';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import _isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';
import max from 'lodash/max';
import maxBy from 'lodash/maxBy';
import partition from 'lodash/partition';
import isEqual from 'react-fast-compare';

import { IEntity, ITable } from '../interfaces';

function isPlainObject(val: any): val is Record<string, any> {
  return _isPlainObject(val);
}
export function mergeDeepChanges<
  T extends Record<string | number, any> | undefined
>(oldVal: T, newVal: T, debug: boolean = false): T {
  debug &&
    console.log(
      'mergeDeepChanges' +
        '\n' +
        JSON.stringify({ oldVal }) +
        '\n' +
        JSON.stringify({ newVal })
    );

  if (!newVal || isEmpty(newVal)) {
    if (isEqual(oldVal, newVal)) {
      return oldVal;
    } else return newVal;
  }

  if (isPlainObject(oldVal) && isPlainObject(newVal)) {
    Object.keys(oldVal).forEach((key) => {
      if (isUndefined(newVal[key])) {
        delete oldVal[key];
      }
    });
  }

  return assignWith(oldVal, newVal, (val, srcVal, key) => {
    if (isArray(srcVal)) {
      if (!srcVal.length) {
        return srcVal;
      } else if (!!srcVal[0].id) {
        return srcVal.map((srcItem) => {
          const destItem =
            val?.find((destItem: IEntity) => destItem.id === srcItem.id) || {};
          return mergeDeepChanges(destItem, srcItem, debug);
        });
      } else {
        return srcVal.map((srcItem, i) =>
          mergeDeepChanges(val?.[i] || {}, srcItem, debug)
        );
      }
    } else if (!isPlainObject(srcVal)) {
      if (isEqual(val, srcVal)) {
        return val;
      } else {
        return srcVal;
      }
    } else {
      return mergeDeepChanges(val, srcVal, debug);
    }
  });
}

export function getActivePlayerIds(
  table: ITable,
  activePlayerIds: string[] | undefined,
  inactivePlayerIds: string[] | undefined
) {
  const players = table.game.players;
  const userId = table.game.me.id;
  const userPlayerIndex = players?.findIndex(({ id }) => id === userId);

  const playersJoinedBeforeUser =
    userPlayerIndex > -1 ? players?.slice(0, userPlayerIndex) || [] : [];
  const playersJoinedAfterUser =
    players?.slice(userPlayerIndex + 1, players.length) || [];

  const [newActivePlayers, newInactivePlayers] = partition(
    [...playersJoinedAfterUser, ...playersJoinedBeforeUser],
    ({ status }) => !['RETIRED', 'INACTIVE'].includes(status)
  );

  const newActivePlayerIds = newActivePlayers.map(({ id }) => id);
  const newInactivePlayerIds = newInactivePlayers.map(({ id }) => id);

  return [
    isEqual(newActivePlayerIds, activePlayerIds)
      ? activePlayerIds
      : newActivePlayerIds,
    isEqual(newInactivePlayerIds, inactivePlayerIds)
      ? inactivePlayerIds
      : newInactivePlayerIds,
  ];
}

export function getMaxStackAndWager(
  table: ITable,
  maxStackAndWager: number | undefined
) {
  const newMaxStackAndWager =
    max(
      table.game.players
        .filter(
          (p) =>
            !['INACTIVE', 'RETIRED', 'FOLDED'].includes(p.status) &&
            p.id !== table.game.me.id
        )
        .map((p) => p.stack + p.wager)
    ) || 0;

  return newMaxStackAndWager === maxStackAndWager
    ? maxStackAndWager
    : newMaxStackAndWager;
}

export function getLargestStack(
  table: ITable,
  largestStack: number | undefined
) {
  const newLargestStack = maxBy(
    table.game.players.filter(
      ({ status }) => !['INACTIVE', 'RETIRED'].includes(status)
    ),
    'stack'
  )?.stack;

  return newLargestStack === largestStack ? largestStack : newLargestStack;
}

export function getLargestWager(
  table: ITable,
  largestWager: number | undefined
) {
  const newLargestWager = maxBy(table.game.players, 'wager')?.wager;

  return newLargestWager === largestWager ? largestWager : newLargestWager;
}

export function getAdminName(table: ITable, adminName: string | undefined) {
  const newAdminName =
    table.game.players.find(({ id }) => id === table.admin_id)?.name || '';
  return newAdminName === adminName ? adminName : newAdminName;
}

export function getIds(entities: IEntity[], ids: string[] | undefined) {
  const newIds = entities.map(({ id }) => id);
  return isEqual(newIds, ids) ? ids : newIds;
}

export function getHasAdd(table: ITable, hasAdd: boolean | undefined) {
  const newHasAdd =
    table.admin_id === table.game.me.id && table.game.players.length < 15;
  return newHasAdd === hasAdd ? hasAdd : newHasAdd;
}

export function getBettingId(table: ITable, bettingId: string | undefined) {
  const newBettingId = table.game.players.find(
    ({ status }) => status === 'BETTING'
  )?.id;
  return newBettingId === bettingId ? bettingId : newBettingId;
}

export function getIsPlayerActive(table: ITable) {
  const status = table.game.players.find(({ id }) => id === table.game.me.id)
    ?.status;
  return ['BETTING', 'IDLE', 'FOLDED'].includes(status || '');
}
