import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import Footer from '../Footer';
import LayoutOnboarding from '../LayoutOnboarding';

const propTypes = {
  onStart: PropTypes.func,
};

const defaultProps = {
  onStart: () => {},
};

const useStyles = makeStyles((theme) => {
  return {
    heading: {
      fontWeight: 800,
      textAlign: 'left',
      lineHeight: 1.1,
      paddingBottom: 40,
      paddingTop: 0,
    },
    startBtn: {
      marginBottom: 16,
    },
  };
});

function LandingView({ onStart }) {
  const classes = useStyles();

  return (
    <LayoutOnboarding>
      <Box className={classes.copy}>
        <Typography variant="h2" component="h1" className={classes.heading}>
          Let's play
          <br />
          poker!
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        onClick={onStart}
        fullWidth
        size="large"
        className={classes.startBtn}
        autoFocus={true}
      >
        Start a game
      </Button>
      <Footer />
    </LayoutOnboarding>
  );
}

LandingView.propTypes = propTypes;
LandingView.defaultProps = defaultProps;

export default LandingView;
