import { createMuiTheme } from '@material-ui/core/styles';

import robotoCondensed_regular_eot from '../assets/fonts/roboto-condensed-v18-latin-regular.eot';
import robotoCondensed_regular_svg from '../assets/fonts/roboto-condensed-v18-latin-regular.svg';
import robotoCondensed_regular_ttf from '../assets/fonts/roboto-condensed-v18-latin-regular.ttf';
import robotoCondensed_regular_woff from '../assets/fonts/roboto-condensed-v18-latin-regular.woff';
import robotoCondensed_regular_woff2 from '../assets/fonts/roboto-condensed-v18-latin-regular.woff2';

const robotoCondensed400 = {
  fontFamily: 'Roboto Condensed',
  fontWeight: 400,
  fontStyle: 'normal',
  src: `url(${robotoCondensed_regular_eot})`,
  // eslint-disable-next-line
  src: `local('Roboto Condensed'), local('RobotoCondensed'),
       url(${robotoCondensed_regular_woff2}) format('woff2'),
       url(${robotoCondensed_regular_woff}) format('woff'),
       url(${robotoCondensed_regular_ttf}) format('truetype'),
       url(${robotoCondensed_regular_svg}) format('svg')`,
};

export const colors = {
  danger: '#EA232A',
  dangerHover: '#BE1C22',
  dark: '#323333',
  gold: '#DCB545',
  grayDarkColor: '#525F6C',
  grayLight: '#ECECEC',
  grayLightColor: '#C8CED7',
  grayMid: '#9A9C9E',
  grayMidColor: '#808B9B',
  light: '#DADDDF',
  primary: '#5C5C5E',
  secondary: '#5FAC88',
  secondaryMuted: '#84B9A2',
  sidePots: ['#6B1DD3', '#F66E00', '#000AF6', '#00CEE7', '#D31D2D'],
  tertiary: '#72B3FA',
  warning: '#FF8A00',
  warningHover: '#D05E00',
  warningOutlineHover: '#FFECD6',
  white: '#FFFEFC',
};

const theme = createMuiTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 481,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      common: {
        white: colors.white,
      },
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      // error: {
      //   main: colors.accent
      // },
      warning: {
        main: colors.warning,
      },
      text: {
        primary: colors.dark,
      },
      grey: {
        200: colors.grayLight,
        300: colors.grayLight,
        500: colors.grayMid,
        600: colors.grayMid,
        700: colors.grayMid,
        900: colors.dark,
        A100: colors.light,
        A200: colors.grayMid,
        A400: colors.dark,
        A700: colors.grayMid,
      },
    },
    typography: {
      caption: {
        fontWeight: 500,
        fontSize: '0.6rem',
        lineHeight: 1.328,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@global': {
            '@font-face': [robotoCondensed400],
          },
          html: {
            minHeight: '100vh',
            // eslint-disable-next-line
            minHeight: 'calc(var(--vh, 1vh) * 100)',
          },
          body: {
            minHeight: '100vh',
            // eslint-disable-next-line
            minHeight: 'calc(var(--vh, 1vh) * 100)',
          },
          '#root': {
            minHeight: '100vh',
            // eslint-disable-next-line
            minHeight: 'calc(var(--vh, 1vh) * 100)',
          },
          '*': {
            touchAction: 'manipulation',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          marginBottom: -15,
        },
      },
    },
  },
  {
    breakpoints: {
      values: {
        sm: 480,
      },
    },
  }
);

export default theme;
