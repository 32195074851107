import { useEffect } from 'react';

import each from 'lodash/each';

export default function useSetRootCssProperty(props) {
  const isClient = typeof document === 'object';

  useEffect(() => {
    isClient &&
      each(props, (val, key) => {
        document.documentElement.style.setProperty(key, val);
      });
  }, [isClient, props]);
}
