import React, { Ref } from 'react';

import {
  InputLabelProps as InputLabelPropsType,
  InputBaseComponentProps,
  InputProps as InputPropsType,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Cleave from 'cleave.js/react';
import clsx from 'clsx';

interface CleaveChangeEvent<T> extends React.ChangeEvent<T> {
  target: { rawValue: string } & EventTarget & T;
}

function InputMask({
  inputRef,
  numeralDecimalScale = 0,
  ...inputProps
}: {
  inputRef: Ref<HTMLInputElement>;
  numeralDecimalScale: number;
}) {
  return (
    <Cleave
      options={{
        numeral: true,
        numeralDecimalScale,
      }}
      {...inputProps}
      type="tel"
    />
  );
}

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: 'Roboto Condensed',
    '&::placeholder': {
      fontFamily: 'Roboto',
    },
  },
}));

function InputNumeric({
  name,
  onChange,
  value,
  InputLabelProps = {},
  inputProps = {},
  numeralDecimalScale = 0,
  InputProps,
  ...otherProps
}: {
  name: string;
  onChange: (e: { target: { name: string; value: string | number } }) => void;
  value?: number | string;
  InputLabelProps?: InputLabelPropsType;
  inputProps?: InputBaseComponentProps;
  numeralDecimalScale?: number;
  InputProps?: InputPropsType;
} & TextFieldProps) {
  const classes = useStyles();

  return (
    <TextField
      onChange={(
        e: CleaveChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        onChange({ target: { name, value: e.target.rawValue } });
      }}
      value={value}
      name={name}
      InputLabelProps={{
        shrink: !!value,
        ...InputLabelProps,
      }}
      InputProps={{
        inputComponent: InputMask as React.ElementType<InputBaseComponentProps>,
        inputProps: {
          'data-testid': `input_${name}`,
          numeralDecimalScale,
          ...inputProps,
          className: clsx(classes.input, inputProps.className),
        },
        ...InputProps,
        ...(!!value
          ? {
              startAdornment: InputProps?.startAdornment,
            }
          : {
              startAdornment: null,
            }),
      }}
      {...otherProps}
    />
  );
}

export default InputNumeric;
