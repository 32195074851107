import React, { useContext, useState } from 'react';

import { observer } from 'mobx-react-lite';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import GameView from '../../components/GameView';
import LobbyView from '../../components/LobbyView';
import ModalSettingsContainer, {
  EModalTypename,
} from '../../containers/ModalSettingsContainer';
import { TableContext } from '../../contexts/TableContext';

const GameContainer = observer(() => {
  const match = useRouteMatch<{ tableId: string }>();
  const location = useLocation();

  const tableStore = useContext(TableContext)!;
  const table = tableStore.table!;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTypename, setModalTypename] = useState<EModalTypename>(
    'GAME_SETTINGS'
  );

  function handleOpenModal(typename: EModalTypename) {
    setModalTypename(typename);
    setIsModalOpen(true);
  }

  if (table.status !== 'IDLE' && location.pathname === `${match.url}/lobby`) {
    return <Redirect to={`${match.url}${location.search}`} />;
  } else if (table.status === 'IDLE' && location.pathname === `${match.url}`) {
    return <Redirect to={`${match.url}/lobby${location.search}`} />;
  }

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/lobby`}>
          <LobbyView handleOpenMenu={() => handleOpenModal('LOBBY')} />
        </Route>
        <Route exact path={match.path}>
          <GameView
            handleOpenSettingsMenu={() => handleOpenModal('GAME_SETTINGS')}
            handleOpenBlindsMenu={() => handleOpenModal('BLINDS')}
          />
        </Route>
        <Redirect to={match.path} />
      </Switch>
      <ModalSettingsContainer
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        typename={modalTypename}
        onSettingsUpdateSuccess={() => setIsModalOpen(false)}
      />
    </>
  );
});

export default GameContainer;
