import { Dictionary } from 'lodash';
import { observable, action, runInAction, makeObservable } from 'mobx';

import {
  ITable,
  IPlayer,
  ISpectator,
  IGhost,
  TCard,
  TTableStoreTable,
  IVictor,
} from '../interfaces';
import * as api from '../services/api';

export default class TableStore {
  @observable tableFromAPI?: ITable;
  @observable table?: TTableStoreTable;
  @observable error?: Error;
  @observable loading?: boolean;

  @observable activePlayerIds?: string[];
  @observable adminName?: string;
  @observable bettingId?: string;
  @observable communityCards?: Record<number, TCard | undefined>;
  @observable ghostIds?: string[];
  @observable ghosts?: Dictionary<IGhost>;
  @observable hands?: Dictionary<Record<number, TCard | undefined>>;
  @observable hasAdd?: boolean;
  @observable inactivePlayerIds?: string[];
  @observable isPlayerActive?: boolean;
  @observable largestStack?: number;
  @observable largestWager?: number;
  @observable maxStackAndWager?: number;
  @observable players?: Dictionary<IPlayer>;
  @observable spectatorIds?: string[];
  @observable spectators?: Dictionary<ISpectator>;
  @observable userHand?: Record<number, TCard | undefined>;
  @observable victors?: Dictionary<IVictor>;
  @observable victorIds?: string[];

  constructor() {
    makeObservable(this);
  }

  @action
  private onError = (err: Error) => {
    this.error = err;
  };

  @action
  private handlePromise = <R extends any, T extends (arg: any) => Promise<R>>(
    fn: T,
    args: Omit<Parameters<T>[0], 'tableId'>,
    isAddingTable = true
  ) => {
    return new Promise<R>((resolve, reject) => {
      if (!!this.table?.table_id) {
        this.loading = true;
        return fn({
          ...(isAddingTable ? { tableId: this.table?.table_id } : {}),
          ...args,
        }).then(
          runInAction(() => {
            this.loading = false;
            return resolve;
          }),
          runInAction(() => {
            this.loading = false;
            return reject;
          })
        );
      } else {
        reject();
      }
    }).catch(this.onError);
  };

  // REST API methods
  addPlayerToTable = (id: string) =>
    this.handlePromise(api.addPlayerToTable, { id });

  @action
  bet = (amount: number) =>
    this.handlePromise(api.bet, {
      id: this.table?.game.me.id || '',
      amount,
    });

  createPlayer = (name: string) =>
    this.handlePromise(api.createPlayer, { name });

  deal = () => this.handlePromise(api.deal, {});

  @action
  emote = (message: string) =>
    this.handlePromise(api.emote, {
      id: this.table?.game.me.id || '',
      message,
    });

  @action
  fold = () =>
    this.handlePromise(api.fold, { id: this.table?.game.me.id || '' });

  playerToSpectator = (id: string) =>
    this.handlePromise(api.playerToSpectator, { id });

  removePlayer = (
    payload: Omit<Parameters<typeof api.removePlayer>[0], 'tableId'>
  ) => this.handlePromise(api.removePlayer, payload);

  @action
  revealCards = (
    cardIndices: Parameters<typeof api.revealCards>[0]['cardIndices']
  ) =>
    this.handlePromise(api.revealCards, {
      id: this.table?.game.me.id || '',
      handId: this.table?.game.hand_id || '',
      cardIndices,
    });

  updateGameSettings = (
    payload: Omit<Parameters<typeof api.updateGameSettings>[0], 'tableId'>
  ) => this.handlePromise(api.updateGameSettings, payload);
}
