import * as React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { colors } from '../../constants/theme';

const useStyles = makeStyles((theme) => {
  return {
    adminTag: {
      fontSize: '0.6rem',
      lineHeight: '0.6rem',
      color: colors.grayMid,
    },
  };
});

function AdminTag({ className }: { className?: string }) {
  const classes = useStyles();

  return (
    <Typography
      variant="overline"
      className={clsx(classes.adminTag, className)}
    >
      Admin
    </Typography>
  );
}

export default AdminTag;
