import React, { useContext } from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';

import { TableContext } from '../../contexts/TableContext';
import GameList, { useGameList } from '../GameList';
import PlayerListItem from '../PlayerListItem';

function PlayerWrapper({
  id,
  ...props
}: React.ComponentProps<typeof PlayerListItem>) {
  const { ListItem } = useGameList();

  return (
    <ListItem>
      <PlayerListItem id={id} {...props} />
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    playersLabel: {
      fontSize: '0.6rem',
    },
  };
});

const PlayerList = observer(
  ({
    hasHeader = false,
    isPending = false,
  }: {
    hasHeader?: boolean;
    isPending: boolean;
  }) => {
    const tableStore = useContext(TableContext)!;
    const adminId = tableStore.table!.admin_id;
    const userId = tableStore.table!.game.me.id;
    const classes = useStyles();
    const hasAdminTools = adminId === userId;

    return (
      <>
        {hasHeader && (
          <>
            <Typography variant="overline" className={classes.playersLabel}>
              Players
            </Typography>
            <Divider />
          </>
        )}
        <GameList>
          {tableStore.activePlayerIds!.map((id) => (
            <PlayerWrapper
              key={id}
              hasAdminTools={hasAdminTools}
              hasRemovePlayerAction={tableStore.activePlayerIds!.length > 1}
              isPending={isPending}
              id={id}
            />
          ))}
        </GameList>
      </>
    );
  }
);

export default PlayerList;
