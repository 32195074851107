import React, { useContext } from 'react';

import { observer } from 'mobx-react-lite';

import { TableContext } from '../../contexts/TableContext';
import ExpansionPanel from '../ExpansionPanel';
import Ghost from '../Ghost';
import PlayerCompact from '../PlayerCompact';
import Spectator from '../Spectator';


export const PlayerListCompact = observer(
  ({ className }: { className?: string }) => {
    const tableStore = useContext(TableContext)!;
    return (
      <>
        {tableStore.inactivePlayerIds!.map((id) => (
          <PlayerCompact id={id} key={id} className={className} />
        ))}
      </>
    );
  }
);

export const SpectatorList = observer(() => {
  const tableStore = useContext(TableContext)!;
  return (
    <>
      {tableStore.spectatorIds!.map((id) => (
        <Spectator id={id} key={id} hasAdd={!!tableStore.hasAdd} />
      ))}
    </>
  );
});

const GhostList = observer(() => {
  const tableStore = useContext(TableContext)!;
  return (
    <>
      {tableStore.ghostIds!.map((id) => (
        <Ghost id={id} key={id} hasAdd={!!tableStore.hasAdd} />
      ))}
    </>
  );
});

const GameListCompact = observer(() => {
  const tableStore = useContext(TableContext)!;
  return (
    <ExpansionPanel
      label="Spectators"
      count={
        tableStore.spectatorIds!.length + tableStore.inactivePlayerIds!.length
      }
    >
      <PlayerListCompact />
      <SpectatorList />
      <GhostList />
    </ExpansionPanel>
  );
});

export default GameListCompact;
