import React, { ReactNode } from 'react';

import { SvgIconTypeMap } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import MuiCloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import MuiSpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { makeStyles } from '@material-ui/styles';

import { colors } from '../../constants/theme';

const useStyles = makeStyles((theme) => {
  return {
    speedDial_actions: {
      '$speedDial_directionLeft &': {
        marginRight: 0,
        paddingRight: 0,
      },
    },
    speedDial_directionLeft: {},
    speedDialIcon: {
      boxShadow: 'none',
      padding: 4,
      width: 'inherit',
      height: 'inherit',
      minHeight: 16,
      background: 'transparent',
      color: colors.grayMid,
      '&:hover $editIcon': {
        color: colors.dark,
      },
      '&:hover $closeIcon': {
        color: colors.dark,
      },
      borderRadius: 4,
      '&:active': {
        boxShadow: 'none',
        background: 'transparent',
      },
      '&:hover': {
        background: 'transparent',
      },
    },
    speedDialFab: {
      display: 'none',
    },
    speedDialLabel: {
      boxShadow: 'none',
      padding: 0,
      background: 'transparent',
    },
    closeIcon: {
      fontSize: '1rem',
    },
    editIcon: {
      fontSize: '1rem',
      transition: 'color 0.2s ease',
    },
  };
});

function SpeedDial({
  actions,
  direction = 'left',
  isOpen = false,
  onClick,
  onClickAway,
  OpenIcon,
  CloseIcon,
}: {
  actions: ReactNode[];
  direction?: 'left' | 'right' | 'up' | 'down';
  isOpen?: boolean;
  onClick: () => void;
  onClickAway: () => void;
  OpenIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  CloseIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}) {
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <MuiSpeedDial
        ariaLabel="yea"
        classes={{
          fab: classes.speedDialIcon,
          actions: classes.speedDial_actions,
          directionLeft: classes.speedDial_directionLeft,
        }}
        direction={direction}
        hidden={!actions.length}
        icon={
          isOpen ? (
            !!CloseIcon ? (
              <CloseIcon className={classes.closeIcon} fontSize="small" />
            ) : (
              <MuiCloseIcon className={classes.closeIcon} fontSize="small" />
            )
          ) : !!OpenIcon ? (
            <OpenIcon className={classes.editIcon} fontSize="small" />
          ) : (
            <EditIcon className={classes.editIcon} fontSize="small" />
          )
        }
        onClick={onClick}
        open={isOpen}
      >
        {actions.map((TooltipTitle, i) => (
          <SpeedDialAction
            key={i}
            tooltipTitle={TooltipTitle}
            tooltipOpen
            icon={null}
            classes={{
              fab: classes.speedDialFab,
              staticTooltipLabel: classes.speedDialLabel,
            }}
          />
        ))}
      </MuiSpeedDial>
    </ClickAwayListener>
  );
}

export default SpeedDial;
