import React, { ReactNode } from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { colors } from '../../constants/theme';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      color: colors.grayMidColor,
      padding: 4,
      minWidth: 40,
      display: 'flex',
      alignContent: 'flex-start',
      background: colors.grayLight,
      '&$outlined': {
        background: 'transparent',
        border: `1px solid ${colors.grayLightColor}`,
      },
    },
    outlined: {},
    label: {},
    keyVal: {
      lineHeight: 1.2,
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
    },
    hasChildren: {
      marginRight: 8,
    },
    wrapper: {
      display: 'flex',
      alignContent: 'center',
      fontFamily: 'Roboto Condensed',
      '&:not(:last-of-type)': {
        marginRight: 8,
      },
    },
    number: {
      fontFamily: 'Roboto Condensed',
      lineHeight: 1.2,
    },
  };
});

function KeyValDisplay({
  className,
  elevation = 1,
  isNumeric = false,
  label,
  children,
  value,
  variant,
}: {
  className?: string;
  elevation?: number;
  isNumeric?: boolean;
  label: string;
  children?: ReactNode;
  value?: number | string;
  variant?: 'outlined';
}) {
  const classes = useStyles();

  return (
    <Paper
      elevation={variant === 'outlined' ? 0 : elevation}
      className={clsx(
        classes.paper,
        { [classes.outlined]: variant === 'outlined' },
        className
      )}
    >
      <Box
        className={clsx(classes.keyVal, { [classes.hasChildren]: !!children })}
      >
        <Typography variant="caption" className={classes.label}>
          {label}
        </Typography>
        <Typography
          variant="body2"
          className={clsx({ [classes.number]: isNumeric })}
        >
          {value}
        </Typography>
      </Box>
      {!!children && <Box className={classes.wrapper}>{children}</Box>}
    </Paper>
  );
}

export default KeyValDisplay;
