import React, { useContext } from 'react';

import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import { PlayerListCompact } from '../GameListCompact';
import KeyValDisplay from '../KeyValDisplay';
import LobbyActions from '../LobbyActions';
import PlayerCompact from '../PlayerCompact';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      height: '100%',
      width: '100%',
      position: 'relative',
      paddingTop: 8,
      maxWidth: 480,
      [theme.breakpoints.down('xs')]: {
        padding: '8px 8px',
        '&:last-of-type': {
          paddingBottom: 0,
        },
      },
    },
    gameDetails: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 8,
    },
    header: {
      color: colors.grayMidColor,
    },
    cashDetails: {
      display: 'flex',
      '& > *': {
        marginRight: 8,
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
    actions: {},
    waitingCard: {
      width: '100%',
      padding: 16,
      display: 'flex',
      position: 'relative',
      zIndex: 1,
      background: colors.grayLight,
    },
    waitingBar: {
      zIndex: 2,
      position: 'relative',
      width: '100%',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      marginTop: -4,
      marginBottom: 8,
    },
    playerList: {
      marginLeft: 0,
    },
  };
});

const LobbyView = observer(
  ({ handleOpenMenu }: { handleOpenMenu: () => void }) => {
    const classes = useStyles();

    const tableStore = useContext(TableContext)!;
    const table = tableStore.table!;

    const adminId = table.admin_id;
    const userId = table.game.me.id;

    const isAdmin = adminId === userId;

    return (
      <Box className={classes.root}>
        <Box className={classes.gameDetails}>
          <Box className={classes.cashDetails}>
            <KeyValDisplay
              elevation={0}
              isNumeric
              label="Stack"
              value={table.settings.starting_stack}
            />
            <KeyValDisplay
              elevation={0}
              isNumeric
              label="Blinds"
              value={`${table.settings.small_blind}/${table.settings.big_blind}`}
            />
          </Box>
        </Box>
        {!isAdmin && (
          <>
            <Paper className={classes.waitingCard} elevation={0}>
              <Typography variant="h6">
                Waiting for {tableStore.adminName || 'admin'} to start the
                game...
              </Typography>
            </Paper>
            <LinearProgress
              variant="query"
              color="secondary"
              className={classes.waitingBar}
            />
          </>
        )}
        <LobbyActions
          className={classes.actions}
          isAdmin={isAdmin}
          isPending={false}
          isStartDisabled={
            (tableStore.spectatorIds?.length || 0) +
              (tableStore.inactivePlayerIds?.length || 0) +
              (tableStore.activePlayerIds?.length || 0) +
              1 <
            2
          }
          onStart={tableStore.deal}
          onEditSettings={handleOpenMenu}
          shareLink={`${process.env.REACT_APP_BASE_URL}/tables/${table.table_id}`}
        />
        <PlayerListCompact className={classes.playerList} />
        <PlayerCompact id={userId} className={classes.playerList} />
      </Box>
    );
  }
);

export default LobbyView;
