import compact from 'lodash/compact';

export default function getSidePotIndiciesForId(
  sidePots: Omit<import('../interfaces').TSidePot, 'side_pot'>[],
  id: string
): number[] {
  return compact(
    sidePots.map(({ players }, index) =>
      players.map(({ id }) => id).includes(id) ? index : null
    )
  );
}
