import React, { useContext, useState } from 'react';

import { Fade } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import compact from 'lodash/compact';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import AdminTag from '../AdminTag';
import SpeedDial from '../SpeedDial';

const useStyles = makeStyles((theme) => {
  return {
    playerPaper: {
      position: 'relative',
      padding: 16,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    paperLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    paperRight: {
      display: 'flex',
      alignItems: 'center',
    },
    cancelIcon: {
      color: colors.grayLight,
      transition: 'color 0.2s ease',
      '&:hover': {
        color: colors.grayMid,
      },
    },
    user: {
      border: `1px solid ${colors.secondary}`,
    },
    chip_root: {
      '& $chip_avatarColorSecondary': {
        backgroundColor: 'transparent',
      },
    },
    chip_avatarColorSecondary: {},
    adminTag: {
      marginLeft: 8,
    },
    stack: {
      marginRight: 8,
      fontFamily: 'Roboto Condensed',
      color: colors.grayMid,
    },
  };
});

const PlayerListItem = observer(
  ({
    hasRemovePlayerAction,
    hasAdminTools,
    isPending,
    id,
  }: {
    hasRemovePlayerAction: boolean;
    hasAdminTools: boolean;
    isPending?: boolean;
    id: string;
  }) => {
    const classes = useStyles();
    const tableStore = useContext(TableContext)!;
    const isAdmin = tableStore.table!.admin_id === id;
    const isUser = tableStore.table!.game.me.id === id;
    const player = tableStore.players![id];
    const [isOpen, setIsOpen] = useState(false);
    const name = player.name;
    const stack = player.stack;

    function handleRemove() {
      return tableStore.removePlayer({ id: player.id, kicked: !isUser });
    }

    const playerActions = compact([
      !!hasRemovePlayerAction && (
        <Chip
          size="small"
          label="Remove"
          onClick={handleRemove}
          disabled={isPending}
          avatar={<DeleteIcon fontSize="small" />}
          classes={{
            root: classes.chip_root,
            avatarColorSecondary: classes.chip_avatarColorSecondary,
          }}
        />
      ),
    ]);

    return (
      <Paper
        className={clsx(classes.playerPaper, {
          [classes.user]: isUser,
        })}
      >
        <Box className={classes.paperLeft}>
          <Typography variant="body1">{name}</Typography>
          {isAdmin && <AdminTag className={classes.adminTag} />}
        </Box>
        <Box className={classes.paperRight}>
          <Fade in={!isOpen}>
            <Typography variant="body2" className={classes.stack}>
              {stack}
            </Typography>
          </Fade>
          {hasAdminTools && (
            // @ts-ignore SpeedDial
            <SpeedDial
              actions={playerActions}
              isOpen={isOpen}
              onClick={() => setIsOpen(!isOpen)}
              onClickAway={() => setIsOpen(false)}
            />
          )}
        </Box>
      </Paper>
    );
  }
);

export default PlayerListItem;
