import React, { useState, ReactNode } from 'react';

import { Theme } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreicon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { colors } from '../../constants/theme';


const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      width: 'calc(100% - 16px)',
      marginLeft: 'auto',
    },
    headerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      color: colors.grayMid,
    },
    header: {
      fontSize: '0.6rem',
      flexGrow: 0,
      marginRight: 4,
      marginLeft: 0,
    },
    divider: {
      borderColor: colors.grayMid,
      margin: '16px 0',
    },
    dividerWrapper: {
      cursor: 'pointer',
      flexGrow: 1,
      flexShrink: 0,
    },
    wrapper: {
      width: 'calc(100% + 4px)',
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
    },
    chevron: {
      fontSize: '0.6rem',
    },
    collapsed: {},
    accordion_root: {
      background: 'transparent',
      boxShadow: 'none',
    },
    accordionSummary_expanded: {},
    accordionSummary_content: {
      margin: 0,
      '&$accordionSummary_expanded': { margin: 0 },
    },
    summaryWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    accordionSummary: {
      paddingLeft: 0,
      minHeight: 'initial',
      '&$accordionSummary_expanded': { minHeight: 'initial' },
    },
    accordionDetails: {
      paddingRight: 0,
      paddingLeft: 0,
      marginLeft: -4,
    },
  };
});

function ExpansionPanel({
  children,
  label = '',
  count,
}: {
  children?: ReactNode[];
  label: string;
  count: number;
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root)}>
      <Accordion
        expanded={!isCollapsed && !!count}
        onChange={() => setIsCollapsed(!isCollapsed)}
        classes={{
          root: classes.accordion_root,
        }}
        defaultExpanded
        TransitionProps={{ unmountOnExit: true }}
      >
        <Box
          className={classes.summaryWrapper}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreicon fontSize="small" className={classes.chevron} />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
            classes={{
              content: classes.accordionSummary_content,
              expanded: classes.accordionSummary_expanded,
            }}
          >
            <Box className={clsx(classes.headerWrapper)}>
              <Typography
                variant="overline"
                component="h3"
                className={classes.header}
              >
                {label}
                {isCollapsed && !!count && ` (${count})`}
              </Typography>
            </Box>
          </AccordionSummary>
          <div className={classes.dividerWrapper}>
            <Divider className={classes.divider} />
          </div>
        </Box>
        <AccordionDetails className={classes.accordionDetails}>
          <Box className={clsx(classes.wrapper)}>{children}</Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default ExpansionPanel;
