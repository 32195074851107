import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import { EPlayerStatus } from '../../interfaces';
import getSidePotIndiciesForId from '../../services/getSidePotIndiciesForId';
import getSidePots from '../../services/getSidePots';

const { sidePots: sidePotBadgeColors } = colors;

const useSidePotsStyles = makeStyles((theme) => ({
  chip: {
    fontSize: '0.6rem',
    height: 16,
  },
  sidePot: {
    background: 'transparent',
    border: '1px solid',
  },
}));

const SidePots = observer(() => {
  const classes = useSidePotsStyles();
  const tableStore = useContext(TableContext)!;
  const table = tableStore.table!;
  const sidePots = getSidePotIndiciesForId(
    getSidePots(table.game.side_pot),
    table.game.me.id
  );
  return (
    <>
      {sidePots.map((index) => (
        <Tooltip
          arrow
          enterDelay={500}
          title={`Side pot ${index + 1}`}
          aria-label={`Side pot ${index + 1}`}
          placement="top"
          key={index}
        >
          <Chip
            size="small"
            className={clsx(classes.chip, classes.sidePot)}
            data-testid={`badgeSidePot${index + 1}`}
            style={{
              borderColor:
                sidePotBadgeColors[index % sidePotBadgeColors.length],
              color: sidePotBadgeColors[index % sidePotBadgeColors.length],
            }}
            label={index + 1}
          />
        </Tooltip>
      ))}
    </>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 2,
  },
  chip: {
    fontSize: '0.6rem',
    height: 16,
  },
  admin: {
    backgroundColor: colors.dark,
    color: colors.white,
  },
  dealer: {
    backgroundColor: colors.secondary,
  },
  smallBlind: {
    backgroundColor: colors.grayLightColor,
  },
  bigBlind: {
    backgroundColor: colors.grayMidColor,
  },
  sidePot: {
    background: 'transparent',
    border: '1px solid',
  },
  overline: {
    fontSize: '0.6rem',
    lineHeight: '0.6rem',
    color: colors.grayMid,
    padding: '0 2px',
  },
}));

const Badges = observer(
  ({
    className,
    id,
    status,
  }: {
    className?: string;
    id: string;
    status?: EPlayerStatus;
  }) => {
    const classes = useStyles();

    const tableStore = useContext(TableContext)!;
    const table = tableStore.table!;

    return (
      <Box className={clsx(classes.root, className)}>
        {table.game.dealer_id === id && (
          <Chip
            size="small"
            className={clsx(classes.chip, classes.dealer)}
            data-testid="badgeDealer"
            label="Dealer"
          />
        )}
        {table.game.big_blind_id === id && (
          <Chip
            size="small"
            className={clsx(classes.chip, classes.bigBlind)}
            data-testid="badgeSmall"
            label="Big blind"
          />
        )}
        {table.game.small_blind_id === id && (
          <Chip
            size="small"
            className={clsx(classes.chip, classes.smallBlind)}
            data-testid="badgeSmall"
            label="Small blind"
          />
        )}
        <SidePots />
        {status === 'RETIRED' && (
          <Typography className={classes.overline} variant="overline">
            Out
          </Typography>
        )}
        {status === 'FOLDED' && (
          <Typography className={classes.overline} variant="overline">
            Folded
          </Typography>
        )}
      </Box>
    );
  }
);

export default Badges;
