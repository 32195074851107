import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { ReactComponent as KHMask } from '../../assets/cards/KH_mask.svg';
import { ReactComponent as QHMask } from '../../assets/cards/QH_mask.svg';
import { colors } from '../../constants/theme';
import CommunityCardsBackground from '../CommunityCardsBackground';

const propTypes = {
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
  error: PropTypes.object,
};

const defaultProps = {
  onSubmit: () => {},
  isPending: false,
  error: null,
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      textAlign: 'center',
      overflow: 'hidden',
      minHeight: 'calc(var(--vh, 1vh) * 100)',
      position: 'relativel',
      background: colors.white,
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'center',
      maxWidth: 480,
      overflow: 'hidden',
      minHeight: 'calc(var(--vh, 1vh) * 100)',
    },
    wrapper: {
      padding: '32px 16px',
      margin: 16,
      width: '100%',
      maxWidth: theme.breakpoints.values['sm'] - 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cards: {
      position: 'relative',
      width: '100%',
      zIndex: 1,
    },
    frame: {
      margin: 8,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      background: colors.light,
      borderRadius: 16,
      flexGrow: 1,
    },
    bgWrapper: {
      height: '100%',
      position: 'absolute',
      zIndex: 0,
      width: '100%',
      minHeight: 'calc(var(--vh, 1vh) * 100)',
      overflow: 'hidden',
    },
    bg: {
      right: -1150,
      top: 637,
    },
    card: {
      position: 'absolute',
      width: '50%',
      height: 'auto',
      transform: 'rotate(15deg)',
      transformOrigin: 'bottom right',
      zIndex: 1,
      filter: 'drop-shadow(-1px 1px 1px rgba(0,0,0,0.09))',
      '&:last-of-type': {
        filter: 'drop-shadow(-2px 2px 6px rgba(0,0,0,0.09))',
        position: 'relative',
        transform: 'rotate(-15deg)',
        zIndex: 2,
      },
    },
    childrenWrapper: {
      width: '100%',
      padding: 8,
      position: 'relative',
      zIndex: 2,
      background: colors.white,
      [theme.breakpoints.up('sm')]: {
        marginBottom: 24,
        padding: 16,
      },
    },
  };
});

function LayoutOnboarding({ children }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.frame}>
          <Box className={classes.cards}>
            <KHMask className={classes.card} />
            <QHMask className={classes.card} />
          </Box>
          <Box className={classes.bgWrapper}>
            <CommunityCardsBackground className={classes.bg} />
          </Box>
        </Box>
        <Box className={classes.childrenWrapper}>{children}</Box>
      </Box>
    </Box>
  );
}

LayoutOnboarding.propTypes = propTypes;
LayoutOnboarding.defaultProps = defaultProps;

export default LayoutOnboarding;
