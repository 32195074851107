import React, { useContext } from 'react';

import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import MenuBarContainer from '../../containers/MenuBarContainer/MenuBarContainer';
import { TableContext } from '../../contexts/TableContext';
import ChipsAnimation from '../ChipsAnimation';
import CommunityCards from '../CommunityCards';
import GameList, { useGameList } from '../GameList';
import GameListCompact from '../GameListCompact';
import PlayerHand from '../PlayerHand';
import UserHand from '../UserHand';


const useChipsAnimationStyles = makeStyles((theme: Theme) => {
  return {
    chipsWrapper: {
      position: 'relative',
      maxWidth: 480,
      margin: '0 auto',
      width: '100%',
    },
    chipsAnimation: {
      position: 'absolute',
      zIndex: 3,
      width: 0,
      bottom: 1,
      left: 8,
    },
  };
});

const ChipsAnimationWrapper = observer(() => {
  const classes = useChipsAnimationStyles();
  const tableStore = useContext(TableContext)!;
  return (
    <Box className={classes.chipsWrapper}>
      <ChipsAnimation
        amount={tableStore.table!.game.pot}
        size="medium"
        className={classes.chipsAnimation}
      />
    </Box>
  );
});

const PlayerList = observer(() => {
  const tableStore = useContext(TableContext)!;
  const table = tableStore.table!;

  const { ListItem } = useGameList();

  return (
    <>
      {tableStore.activePlayerIds!.map((id) => {
        return (
          <ListItem key={id}>
            <PlayerHand
              playerId={id}
              isGameActive={table.game.status === 'ACTIVE'}
            />
          </ListItem>
        );
      })}
    </>
  );
});

const useUserHandStyles = makeStyles((theme: Theme) => {
  return {
    userHand: {
      zIndex: 2,
      userSelect: 'none',
      width: '100%',
    },
  };
});

const UserWrapper = observer(() => {
  const classes = useUserHandStyles();
  const { ListItem } = useGameList();
  const tableStore = useContext(TableContext)!;
  const table = tableStore.table!;
  const userId = table.game.me.id;
  const user = tableStore.players![userId];

  return !!user ? (
    <ListItem>
      <UserHand className={classes.userHand} />
    </ListItem>
  ) : null;
});

const GameListCompactWrapper = observer(() => {
  const { ListItem } = useGameList();
  const tableStore = useContext(TableContext)!;
  return tableStore.spectatorIds!.length +
    tableStore.inactivePlayerIds!.length ? (
    <ListItem>
      <GameListCompact />
    </ListItem>
  ) : null;
});

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      minHeight: 'calc(var(--vh, 1vh) * 100)',
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    top: {
      width: '100%',
      zIndex: 2,
      transition: 'transform 0.2s ease',
      '@media screen and ( max-height: 650px )': {
        '&$alwaysCollapsed': {
          transform: 'translateY(-44px)',
          '& $communityCardsWrapper': {
            transform: 'translateY(44px)',
          },
        },
        '&$collapsed': {
          transform: 'translateY(-44px)',
          '& $communityCardsWrapper': {
            transform: 'translateY(44px)',
          },
        },
      },
      userSelect: 'none',
      position: 'fixed',
      top: 0,
    },
    optionsWrapper: {
      position: 'relative',
      maxWidth: 480,
      margin: '0 auto',
      zIndex: 2,
    },
    options: {
      right: -20,
      top: -44,
      [theme.breakpoints.down('xs')]: {
        padding: '0 8px 8px',
        right: 0,
      },
    },
    communityCardsWrapper: {},
    communityCards: {
      position: 'relative',
      zIndex: 1,
      borderBottom: `1px solid ${colors.grayLight}`,
    },
    chipsWrapper: {
      position: 'relative',
      maxWidth: 480,
      margin: '0 auto',
      width: '100%',
    },
    chipsAnimation: {
      position: 'absolute',
      zIndex: 3,
      width: 0,
      bottom: 1,
      left: 8,
    },
    hands: {
      position: 'relative',
      zIndex: 1,
      maxWidth: 480,
      margin: '0 auto',
      transition: 'padding-top 0.2s ease',
      userSelect: 'none',
      paddingTop: 148,
      '@media screen and ( max-height: 650px )': {
        paddingTop: 104,
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
      },
    },
    collapsed: {},
    alwaysCollapsed: {},
  };
});

function GameView({
  handleOpenBlindsMenu,
  handleOpenSettingsMenu,
}: {
  handleOpenBlindsMenu: () => void;
  handleOpenSettingsMenu: () => void;
}) {
  const classes = useStyles();

  const isCollapsed = true;

  return (
    <Box className={classes.root}>
      <Box
        className={clsx(classes.top, {
          [classes.collapsed]: isCollapsed,
        })}
      >
        <CommunityCards
          className={clsx(classes.communityCards)}
          wrapperClassName={clsx(classes.communityCardsWrapper)}
        />
        <MenuBarContainer
          {...{
            handleOpenBlindsMenu,
            handleOpenSettingsMenu,
          }}
        />
        <ChipsAnimationWrapper />
      </Box>
      <GameList
        className={clsx(classes.hands, {
          [classes.collapsed]: isCollapsed,
        })}
      >
        <>
          <PlayerList />
          <GameListCompactWrapper />
          <UserWrapper />
        </>
      </GameList>
    </Box>
  );
}

export default GameView;
