import React, { useCallback, useState } from 'react';

import find from 'lodash/find';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import JoinView from '../../components/JoinView';
import { TableContextProvider } from '../../containers/TableContextProvider';
import { ITable } from '../../interfaces';
import { createPlayer } from '../../services/api';
import { ColorschemeContextProvider } from '../../services/useColorscheme';
import { SfxContextProvider } from '../../services/useSfx';
import { useSnackbars } from '../../services/useSnackbars';
import GameContainer from '../GameContainer';
import TableEventHandler from '../TableEventHandler';
import { TTableIndex } from '../TablesContainer';

const SNACKBAR_ID = 'TABLE_CONNECTION_STATUS';

function TableContainer({
  tablesIndex,
  handleJoinTableSuccess,
  handleCloseConnection,
  userId: userIdFromTableCreation,
}: {
  tablesIndex: TTableIndex[];
  handleJoinTableSuccess: (table: ITable) => void;
  handleCloseConnection?: () => void;
  userId?: string;
}) {
  const location = useLocation();
  const match = useRouteMatch<{ tableId: string }>();

  const setSnackbars = useSnackbars();

  const userId =
    (!!tablesIndex.length &&
      find(tablesIndex, {
        tableId: match.params.tableId,
      })?.userId) ||
    userIdFromTableCreation;

  const handleTableConnectionSuccess = useCallback(() => {
    return setSnackbars([
      {
        id: SNACKBAR_ID,
        severity: 'success',
        message: `Connected!`,
        icon: '🎉',
      },
    ]);
  }, [setSnackbars]);

  const handleCloseTableConnection = useCallback(() => {
    setSnackbars([
      {
        id: SNACKBAR_ID,
        severity: 'warning',
        message: 'Your connection was closed.',
        icon: '🙊',
      },
    ]);
    handleCloseConnection?.();
  }, [handleCloseConnection, setSnackbars]);

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<Error>();

  function handleSubmit(
    payload: Omit<Parameters<typeof createPlayer>[0], 'tableId'>
  ) {
    setIsPending(true);
    return createPlayer({
      tableId: match.params.tableId,
      ...payload,
    })
      .then((resp) => {
        setIsPending(false);
        handleJoinTableSuccess(resp);
      })
      .catch((err) => {
        setIsPending(false);
        setError(err);
      });
  }

  return (
    <Switch>
      <Route exact path={`${match.path}/join`}>
        {userId ? (
          <Redirect to={`${match.url}${location.search}`} />
        ) : (
          <JoinView
            onSubmit={handleSubmit}
            isPending={isPending}
            error={error}
          />
        )}
      </Route>
      <Route path={match.path}>
        {!userId ? (
          <Redirect to={`${match.url}/join${location.search}`} />
        ) : (
          <TableContextProvider
            tableId={match.params.tableId}
            userId={userId}
            onClose={handleCloseTableConnection}
            onSuccess={handleTableConnectionSuccess}
          >
            {({ isConnected, isConnecting, tableStore }) => {
              if (tableStore.tableFromAPI) {
                return (
                  <TableEventHandler>
                    <ColorschemeContextProvider>
                      <SfxContextProvider>
                        <GameContainer />
                      </SfxContextProvider>
                    </ColorschemeContextProvider>
                  </TableEventHandler>
                );
              }
              if (isConnecting) {
                return 'Connecting...';
              }
              return 'Waiting...';
            }}
          </TableContextProvider>
        )}
      </Route>
      <Redirect to="/tables" />
    </Switch>
  );
}

export default TableContainer;
