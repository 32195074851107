import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import * as yup from 'yup';

import InputNumeric from '../InputNumeric';

const useStyles = makeStyles((theme) => {
  return {
    label: {
      paddingTop: 8,
      paddingLeft: 12,
    },
    startingStackInput: {
      marginBottom: 24,
    },
    input: {
      fontSize: 24,
      lineHeight: '24px',
    },
    blinds: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 16,
      '&:not(:last-of-type)': {
        marginRight: 16,
      },
    },
    inputLabel_formControl: {
      '&:not($inputLabel_shrink)': {
        transform: 'translate(0, 32px) scale(1)',
      },
    },
    inputLabel_shrink: {},
    menuItem: {
      fontFamily: 'Roboto Condensed',
      fontSize: 16,
      lineHeight: '20px',
    },
  };
});

export const validation = {
  small_blind: yup
    .number()
    .integer('No decimals allowed!')
    .min(10, 'Must be 10 or more')
    .required('Pick a small blind'),
  big_blind: yup
    .number()
    .integer('No decimals allowed!')
    .when('small_blind', (sb: number, schema: yup.NumberSchema) => {
      return sb
        ? schema.min(sb + 1, 'Must be more than small blind')
        : schema.min(11);
    })
    .min(20, 'Must be 20 or more')
    .required('Pick a big blind'),
  starting_stack: yup
    .number()
    .integer('No decimals allowed!')
    .when(['big_blind'], (big_blind: number, schema: yup.NumberSchema) => {
      return big_blind
        ? schema.min(
            big_blind,
            'Stack can’t be less than big blind, that doesn’t even make sense!'
          )
        : schema.min(0);
    })
    .required('Set an initial amount for each player'),
};

export type EModalComponent = 'BLINDS' | 'STARTING_STACK';

export type TSettingsFormValues = {
  small_blind?: number;
  big_blind?: number;
  starting_stack?: number;
};

function SettingsFields({
  classes: classesProp = {},
  errors = {},
  isDisabled = false,
  names = {},
  onBlur,
  onChange,
  settingsConfig,
  touched = {},
  values = {},
}: {
  classes?: { [key: string]: string };
  errors?: FormikErrors<TSettingsFormValues>;
  isDisabled?: boolean;
  names?: { [key: string]: string };
  onBlur: React.EventHandler<any>;
  onChange: React.EventHandler<any>;
  settingsConfig: EModalComponent[];
  touched?: FormikTouched<TSettingsFormValues>;
  values?: TSettingsFormValues;
}) {
  const classes = useStyles();

  return (
    <>
      {settingsConfig.includes('STARTING_STACK') && (
        <InputNumeric
          className={classes.startingStackInput}
          label="Starting stack"
          name={names.starting_stack || 'starting_stack'}
          value={values.starting_stack}
          fullWidth
          disabled={isDisabled}
          onChange={onChange}
          onBlur={onBlur}
          inputProps={{
            className: clsx(classes.input, classesProp.input),
          }}
          InputLabelProps={{
            classes: {
              shrink: clsx(
                classes.inputLabel_shrink,
                classesProp.inputLabel_shrink
              ),
              formControl: clsx(
                classes.inputLabel_formControl,
                classesProp.inputLabel_formControl
              ),
            },
          }}
          error={touched.starting_stack && !!errors.starting_stack}
          helperText={touched.starting_stack ? errors.starting_stack : ''}
        />
      )}
      {settingsConfig.includes('BLINDS') && (
        <Box className={classes.blinds}>
          <InputNumeric
            className={classes.blinds}
            label="Small blind"
            name={names.small_blind || 'small_blind'}
            value={values.small_blind}
            disabled={isDisabled}
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{
              className: clsx(classes.input, classesProp.input),
            }}
            InputLabelProps={{
              classes: {
                shrink: clsx(
                  classes.inputLabel_shrink,
                  classesProp.inputLabel_shrink
                ),
                formControl: clsx(
                  classes.inputLabel_formControl,
                  classesProp.inputLabel_formControl
                ),
              },
            }}
            error={touched.small_blind && !!errors.small_blind}
            helperText={touched.small_blind ? errors.small_blind : ''}
          />
          <InputNumeric
            className={classes.blinds}
            label="Big blind"
            name={names.big_blind || 'big_blind'}
            value={values.big_blind}
            disabled={isDisabled}
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{
              className: clsx(classes.input, classesProp.input),
            }}
            InputLabelProps={{
              classes: {
                shrink: clsx(
                  classes.inputLabel_shrink,
                  classesProp.inputLabel_shrink
                ),
                formControl: clsx(
                  classes.inputLabel_formControl,
                  classesProp.inputLabel_formControl
                ),
              },
            }}
            error={touched.big_blind && !!errors.big_blind}
            helperText={touched.big_blind ? errors.big_blind : ''}
          />
        </Box>
      )}
    </>
  );
}

export default SettingsFields;
