import React from 'react';

import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

import MenuBar from '../../components/MenuBar';

const useStyles = makeStyles((theme: Theme) => {
  return {
    menuBarWrapper_relative: {
      position: 'relative',
      zIndex: 2,
      width: '100%',
      maxWidth: 716,
      margin: '0 auto',
    },
    menuBarWrapper_absolute: {
      position: 'absolute',
      height: 0,
      bottom: 60,
      zIndex: 2,
      width: '100%',
      margin: '0 auto',
    },
    menuBar: {
      padding: 8,
    },
  };
});

function MenuBarContainer({
  handleOpenBlindsMenu,
  handleOpenSettingsMenu,
}: {
  handleOpenBlindsMenu: () => void;
  handleOpenSettingsMenu: () => void;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.menuBarWrapper_relative}>
      <Box className={classes.menuBarWrapper_absolute}>
        <MenuBar
          className={classes.menuBar}
          {...{
            handleOpenBlindsMenu,
            handleOpenSettingsMenu,
          }}
        />
      </Box>
    </Box>
  );
}

export default MenuBarContainer;
