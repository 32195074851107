import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useSnackbars } from '../../services/useSnackbars';

const useStyles = makeStyles((theme) => ({
  rowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  row: {
    marginBottom: 8,
    flex: '0 1 100%',
  },
}));

function LobbyActions({
  onStart,
  onEditSettings,
  className,
  isPending = false,
  shareLink,
  isStartDisabled = false,
  isAdmin = false,
}: {
  onStart: () => void;
  onEditSettings: () => void;
  className?: string;
  isPending?: boolean;
  shareLink: string;
  isStartDisabled?: boolean;
  isAdmin?: boolean;
}) {
  const classes = useStyles();
  const setSnackbars = useSnackbars();

  const buttonProps = {
    fullWidth: true,
    disableElevation: true,
    disabled: isPending,
    className: classes.row,
  };

  return (
    <Box className={className}>
      {isAdmin && (
        <Button
          {...buttonProps}
          variant="contained"
          color="secondary"
          onClick={onStart}
          disabled={isStartDisabled || isPending}
        >
          Start game
        </Button>
      )}
      <CopyToClipboard
        text={shareLink}
        onCopy={() =>
          setSnackbars([
            {
              id: 'COPIED_LINK',
              severity: 'success',
              message: `Copied to clipboard!`,
              icon: '📋',
            },
          ])
        }
      >
        <Button {...buttonProps} variant="outlined" color="primary">
          Copy invite link
        </Button>
      </CopyToClipboard>
      {isAdmin && (
        <Button
          {...buttonProps}
          variant="outlined"
          color="primary"
          onClick={onEditSettings}
        >
          Edit game settings
        </Button>
      )}
    </Box>
  );
}

export default LobbyActions;
