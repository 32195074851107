import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Footer from '../Footer';
import LayoutOnboarding from '../LayoutOnboarding';

const propTypes = {
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
  error: PropTypes.object,
};

const defaultProps = {
  onSubmit: () => {},
  isPending: false,
  error: null,
};

const useStyles = makeStyles((theme) => {
  return {
    nameInput: {
      marginBottom: 32,
    },
    heading: {
      fontWeight: 800,
      textAlign: 'left',
      lineHeight: 1.1,
    },
    copy: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    caption: {
      marginBottom: 8,
    },
    joinBtn: {
      marginBottom: 16,
    },
  };
});

function JoinView({ onSubmit, isPending, error }) {
  const classes = useStyles();

  function handleSubmit({ playerName: name }) {
    onSubmit({ name });
  }

  return (
    <LayoutOnboarding>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ playerName: '' }}
        validationSchema={yup.object().shape({
          playerName: yup
            .string()
            .max(28, 'Needs to be less than 28 characters 😅')
            .required('Hold up but what is your name'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <Box component="form" onSubmit={handleSubmit}>
              <Box className={classes.copy}>
                <Typography
                  variant="h4"
                  component="h1"
                  className={classes.heading}
                >
                  Join this game
                </Typography>
              </Box>
              <TextField
                className={classes.nameInput}
                label="Name"
                name="playerName"
                value={values.playerName}
                fullWidth
                disabled={isPending}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.playerName && !!errors.playerName}
                helperText={touched.playerName ? errors.playerName : ''}
                autoFocus={true}
                inputProps={{
                  maxLength: 28,
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                type="submit"
                disabled={isPending}
                size="large"
                disableElevation
                className={classes.joinBtn}
              >
                Join
              </Button>
              {!!error && (
                <Typography
                  color="error"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  Yeaahhh...something is not working quite right, sorry.{' '}
                  <span role="img" aria-label="sad face">
                    😔
                  </span>{' '}
                  Maybe try again?
                </Typography>
              )}
              <Footer />
            </Box>
          );
        }}
      </Formik>
    </LayoutOnboarding>
  );
}

JoinView.propTypes = propTypes;
JoinView.defaultProps = defaultProps;

export default JoinView;
