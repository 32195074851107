import {
  useCallback,
  useEffect,
  useRef,
  ReactElement,
  useContext,
} from 'react';

import { Dictionary } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import isEqual from 'react-fast-compare';

import { TableContext } from '../../contexts/TableContext';
import { ETableEvent, IPlayer } from '../../interfaces';
import { useSnackbars } from '../../services/useSnackbars';

function handleScrollToUserHand(
  oldBettingId: string | undefined,
  newBettingId: string,
  userId: string
) {
  if (oldBettingId !== newBettingId && newBettingId === userId) {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }
}

const TableEventHandler = observer(
  ({ children }: { children: ReactElement }) => {
    const tableStore = useContext(TableContext)!;
    const tableFromApi = tableStore.tableFromAPI!;
    const setSnackbars = useSnackbars();
    const userId = tableStore.table!.game.me.id;
    const userObject = tableStore.players![userId];
    const playerStatus = userObject?.status || 'INACTIVE';
    const tableFromApiRef = useRef(tableFromApi);
    const bettingId = tableStore.bettingId;
    const bettingIdRef = useRef(tableStore.bettingId);
    const players = tableStore.players;
    const playersRef = useRef(tableStore.players);

    const handleAddRemovePlayer = useCallback(
      (
        event: ETableEvent,
        oldPlayers: Dictionary<IPlayer>,
        newPlayers: Dictionary<IPlayer>
      ) => {
        const oldKeys = Object.keys(oldPlayers);
        const newKeys = Object.keys(newPlayers);
        const removedPlayerIds = difference(oldKeys, newKeys);
        const addedPlayerIds = difference(newKeys, oldKeys);
        switch (event) {
          case 'KICK':
            const adminName = tableStore.adminName;
            setSnackbars([
              {
                id: 'PLAYER_KICK',
                severity: 'warning',
                message: `${
                  oldPlayers?.[removedPlayerIds[0]]?.name || 'Someone'
                } was kicked by ${adminName}`,
                icon: '🥾',
              },
            ]);
            break;
          case 'LEAVE':
            setSnackbars([
              {
                id: 'PLAYER_LEAVE',
                severity: 'success',
                message: `${
                  oldPlayers?.[removedPlayerIds[0]]?.name || 'Someone'
                } left`,
                icon: '🚪',
              },
            ]);
            break;
          case 'JOIN':
            setSnackbars([
              {
                id: 'PLAYER_JOIN',
                severity: 'success',
                message: `${
                  players?.[addedPlayerIds[0]]?.name || 'Someone else'
                } joined`,
                icon: '👋',
              },
            ]);
            break;
          default:
            break;
        }
      },
      [players, setSnackbars, tableStore.adminName]
    );

    useEffect(
      function handleTableUpdate() {
        autorun(() => {
          if (
            tableFromApiRef.current &&
            !!tableFromApi &&
            !isEqual(tableFromApi, tableFromApiRef.current)
          ) {
            if (['KICK', 'LEAVE', 'JOIN'].includes(tableFromApi.last_event)) {
              handleAddRemovePlayer(
                tableFromApi.last_event,
                cloneDeep(playersRef.current!),
                players!
              );
            }

            if (
              tableFromApi.game.status === 'COMPLETE' &&
              tableFromApiRef.current.game.status !== 'COMPLETE' &&
              !!tableFromApi.game.victors.length
            ) {
              setSnackbars([
                {
                  id: 'VICTORS',
                  severity: 'success',
                  message: tableFromApi.game.victors
                    .map(({ name }) => `${name} wins!`)
                    .join(' '),
                  icon: '💰',
                },
              ]);
            }
          }
        });
        tableFromApiRef.current = tableFromApi;
        playersRef.current = players;
      },
      [handleAddRemovePlayer, playerStatus, players, setSnackbars, tableFromApi]
    );

    useEffect(
      function handleBettingIdUpdate() {
        autorun(() => {
          if (
            bettingIdRef.current &&
            !!bettingId &&
            bettingId !== bettingIdRef.current
          ) {
            handleScrollToUserHand(
              bettingIdRef.current,
              bettingId,
              tableFromApi.game.me.id
            );
          }
        });
        bettingIdRef.current = bettingId;
      },
      [bettingId, tableFromApi.game.me.id]
    );

    return children;
  }
);

export default TableEventHandler;
