import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  createContext,
  ReactNode,
} from 'react';

import localforage from 'localforage';

import { colors } from '../constants/theme';
import { ESuit } from '../interfaces';

export type EColorscheme = 'FOUR_COLOR' | 'RAINBOW' | 'TRADITIONAL';
export type TColorscheme = Record<ESuit, string>;
export type TColorschemeConfigItem = {
  id: EColorscheme;
  label: string;
  values: TColorscheme;
};

const ColorschemeContext = createContext<
  | {
      setColorscheme: (schemeId: EColorscheme) => void;
      colorscheme?: TColorscheme;
    }
  | undefined
>(undefined);

const colorschemeKey = 'COLORSCHEME';

export const colorschemes: TColorschemeConfigItem[] = [
  {
    id: 'TRADITIONAL',
    label: '2 color suits',
    values: {
      clubs: '#000',
      diamonds: '#d40000',
      hearts: '#d40000',
      spades: '#000',
    },
  },
  {
    id: 'FOUR_COLOR',
    label: '4 color suits',
    values: {
      clubs: colors.secondary,
      diamonds: '#2f89e8',
      hearts: '#d40000',
      spades: '#000',
    },
  },
  {
    id: 'RAINBOW',
    label: 'Rainbow',
    values: {
      clubs: '#13D4AD',
      diamonds: '#F71674',
      hearts: '#6D13D4',
      spades: '#216BEB',
    },
  },
];

function getColorScheme(id: EColorscheme) {
  return colorschemes.find((s) => s.id === id)?.values;
}

export function useColorscheme() {
  const context = useContext(ColorschemeContext);
  if (!context) {
    throw new Error(
      'useColorscheme must be used within a ColorschemeContextProvider'
    );
  }

  const { setColorscheme, colorscheme } = context;

  return {
    setColorscheme,
    colorscheme,
  };
}

export function ColorschemeContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [colorscheme, setColorscheme] = useState(getColorScheme('TRADITIONAL'));

  const cachedScheme = useMemo(() => colorscheme, [colorscheme]);

  function handleSetColorscheme(schemeId: EColorscheme) {
    const scheme = getColorScheme(schemeId);
    setColorscheme(scheme);
    localforage.setItem(colorschemeKey, scheme);
  }

  useEffect(function handleSetColorschemeOnMount() {
    localforage
      .getItem<TColorscheme>(colorschemeKey)
      .then((scheme) =>
        setColorscheme(scheme || getColorScheme('TRADITIONAL'))
      );
  }, []);

  return (
    <ColorschemeContext.Provider
      value={{
        setColorscheme: handleSetColorscheme,
        colorscheme: cachedScheme,
      }}
    >
      {children}
    </ColorschemeContext.Provider>
  );
}
