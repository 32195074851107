import React, { useContext } from 'react';

import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import compact from 'lodash/compact';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import { IPlayer } from '../../interfaces';
import getPlayerContext from '../../services/getPlayerContext';
import AdminTag from '../AdminTag';
import Cards from '../Cards';
import ChipsAnimation from '../ChipsAnimation';
import EmoteBackground from '../EmoteBackground';
import HandCategory from '../HandCategory';
import HandTopElements from '../HandTopElements';
import Stack from '../Stack';
import Wager from '../Wager';

const useChipAnimationStyles = makeStyles((theme: Theme) => {
  return {
    chipsAnimation: {
      marginBottom: 8,
      width: 0,
      filter: `drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06))
        drop-shadow(0 3px 1px rgba(0, 0, 0, 0.07))
        drop-shadow(0 1px 5px rgba(0, 0, 0, 0.06))
        drop-shadow(0 -1px 2px rgba(0, 0, 0, 0.05))`,
    },
  };
});

const ChipsAnimationWrapper = observer(
  ({ player, isGameActive }: { player: IPlayer; isGameActive: boolean }) => {
    const tableStore = useContext(TableContext)!;
    const hand = tableStore.hands![player.id];
    const classes = useChipAnimationStyles();

    return !!player.wager &&
      isGameActive &&
      player.status !== 'FOLDED' &&
      !hand[0] &&
      !hand[1] ? (
      <ChipsAnimation
        amount={player.wager}
        className={classes.chipsAnimation}
        direction="left"
        size="large"
      />
    ) : null;
  }
);

const useCardsWrapperStyles = makeStyles((theme: Theme) => {
  return {
    cardsWrapper: {
      marginLeft: 8,
      position: 'relative',
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        width: 132,
        textAlign: 'right',
      },
    },
    handCategory: {
      marginBottom: 8,
    },
    cards: {
      transformOrigin: 'top right',
      transition: 'transform 0.2s ease-in-out',
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
      },
    },
    isShowingCards: {
      transform: 'scale(2.2)',
      [theme.breakpoints.up('sm')]: {
        transform: 'none',
      },
    },
  };
});

const CardsWrapper = observer(({ player }: { player: IPlayer }) => {
  const classes = useCardsWrapperStyles();
  const tableStore = useContext(TableContext)!;
  const isNotMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('sm')
  );
  const victorHandCategory = tableStore.victors?.[player.id]?.category;

  return player.status !== 'INACTIVE' && player.status !== 'RETIRED' ? (
    <Box className={classes.cardsWrapper}>
      {victorHandCategory && (
        <HandCategory
          category={victorHandCategory}
          className={classes.handCategory}
        />
      )}
      <Cards
        playerId={player.id}
        size={isNotMobile ? 'large' : 'medium'}
        className={clsx(classes.cards, {
          [classes.isShowingCards]: !!compact(
            Object.values(tableStore.hands![player.id])
          ).length,
        })}
      />
    </Box>
  ) : null;
});

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      width: '100%',
    },
    wrapper: {
      width: '100%',
      position: 'relative',
      '&$folded': {
        width: 'calc(100% - 16px)',
        marginLeft: 'auto',
        color: colors.grayMid,
      },
      '&$retired': {
        width: 'calc(100% - 16px)',
        marginLeft: 'auto',
        color: colors.grayMid,
      },
    },
    paper: {
      width: '100%',
      minHeight: 24,
      padding: 8,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      borderTopLeftRadius: 0,
      transition: 'background-color 0.4s ease-in-out',
      zIndex: 1,
      '&$betting': {
        border: `1px solid ${colors.secondary}`,
        padding: 7,
      },
      '&$folded': {
        background: colors.grayLight,
        color: colors.grayMid,
        '& $cards': {
          filter: 'saturate(0.5) contrast(0.5) brightness(115%)',
        },
      },
      '&$retired': {
        background: colors.grayLight,
        color: colors.grayMid,
      },
      '&$victor': {
        border: `1px solid ${colors.gold}`,
        padding: 7,
        background: `linear-gradient(
          156deg, 
          rgba(252,246,186,1) 9%, 
          rgba(247,236,172,1) 18%, 
          rgba(247,232,148,1) 20%, 
          rgba(255,235,120,1) 32%, 
          rgba(246,231,85,1) 39%, 
          rgba(233,212,77,1) 48%, 
          rgba(255,238,163,1) 61%, 
          rgba(251,245,183,1) 66%, 
          rgba(245,232,118,1) 79%, 
          rgba(241,235,177,1) 97%
        )`,
        '&:after': {
          animation: '$shine 5s ease-in-out infinite',
          animationFillMode: 'forwards',
          content: '""',
          position: 'absolute',
          top: '-110%',
          left: '-210%',
          width: '100%',
          height: '400%',
          opacity: 0,
          background: `linear-gradient(
              to right,
              rgba(255,255,255,0.13) 0%,
              rgba(255,255,255,0.13) 77%,
              rgba(255,255,255,0.5) 92%, 
              rgba(255,255,255,0.0) 100%
            )`,
          transform: 'rotate(30deg)',
          pointerEvents: 'none',
        },
      },
    },
    bettingBar: {
      zIndex: 2,
      position: 'relative',
      width: '100%',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      border: `1px solid ${colors.secondary}`,
      borderBottom: 0,
      marginTop: -4,
    },
    cards: {},
    betting: {},
    folded: {},
    retired: {},
    victor: {},
    '@keyframes shine': {
      '10%': {
        opacity: '1',
        top: '-160%',
        left: '30%',
        transitionProperty: 'left,top,opacity',
        transitionDuration: '0.9s,0.9s,0.12s',
        transitionTimingFunction: 'ease',
      },
      '100%': {
        opacity: '0',
        top: '-160%',
        left: '30%',
        transitionProperty: 'left,top,opacity',
      },
    },
    left: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      position: 'relative',
    },
    main: {
      width: '100%',
      position: 'relative',
    },
    stack: {
      zIndex: 2,
    },
    emoteBackground: {
      width: '100%',
      height: '100%',
    },
  };
});

const PlayerHand = observer(
  ({ playerId, isGameActive }: { playerId: string; isGameActive: boolean }) => {
    const classes = useStyles();
    const tableStore = useContext(TableContext)!;
    const player = tableStore.players![playerId];
    const {
      hasFolded,
      isAdmin,
      isAllIn,
      isBetting,
      isRetired,
      isInactive,
    } = getPlayerContext(player.id, tableStore);

    const statusClasses = [
      { [classes.betting]: isGameActive && isBetting },
      { [classes.folded]: hasFolded },
      { [classes.retired]: isRetired || isInactive },
      { [classes.victor]: tableStore.victorIds?.includes(playerId) },
    ];

    return (
      <Box className={clsx(classes.root)}>
        <Box className={clsx(classes.wrapper, ...statusClasses)}>
          <HandTopElements
            {...{
              isGameActive,
              player,
            }}
          />
          <Paper
            elevation={isBetting && isGameActive ? 2 : 1}
            className={clsx(classes.paper, ...statusClasses)}
            data-testid={`playerHand_${player.name}`}
          >
            <Box className={classes.left} data-testid="playerLabels">
              {isAdmin && <AdminTag />}
              {!isRetired && !isInactive && (
                <>
                  <Box className={classes.main}>
                    <Stack
                      isAllIn={isAllIn}
                      stack={player.stack}
                      className={classes.stack}
                    />

                    {!hasFolded && (
                      <Wager amount={player.wager || 0} isAllIn={isAllIn} />
                    )}
                  </Box>
                </>
              )}
              <EmoteBackground
                person={player}
                className={classes.emoteBackground}
              />
            </Box>
            <ChipsAnimationWrapper
              player={player}
              isGameActive={isGameActive}
            />
            <CardsWrapper player={player} />
          </Paper>
          {isBetting && isGameActive && (
            <LinearProgress
              variant="query"
              color="secondary"
              className={classes.bettingBar}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export default PlayerHand;
