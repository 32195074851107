import React, { useContext, useState } from 'react';

import { Theme, Fade } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import compact from 'lodash/compact';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import SpeedDial from '../SpeedDial';

const useStyles = makeStyles((theme: Theme) => {
  return {
    user: {
      border: `1px solid ${colors.secondary}`,
    },
    paper: {
      width: '100%',
      marginLeft: 4,
      marginBottom: 4,
      minHeight: 24,
      padding: 8,
      overflow: 'hidden',
      position: 'relative',
      zIndex: 1,
      background: colors.grayLight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        flex: '1 1 148px',
      },
    },
    paperLeft: {
      display: 'flex',
      alignItems: 'center',
      height: 24,
    },
    paperRight: {
      display: 'flex',
      alignItems: 'center',
    },
    stack: {
      marginRight: 8,
      fontFamily: 'Roboto Condensed',
      color: colors.grayMid,
    },
    chip_root: {
      '& $chip_avatarColorSecondary': {
        backgroundColor: 'transparent',
      },
    },
    chip_avatarColorSecondary: {},
  };
});

const Ghost = observer(
  ({
    id,
    hasAdd = false,
    isPending = false,
  }: {
    id: string;
    hasAdd: boolean;
    isPending?: boolean;
  }) => {
    const [openId, setOpenId] = useState('');
    const tableStore = useContext(TableContext)!;
    const table = tableStore.table!;
    const ghost = tableStore.ghosts![id];

    const classes = useStyles();

    const ghostActions = compact([
      hasAdd && (
        <Chip
          size="small"
          color="secondary"
          label="Add to game"
          onClick={() => tableStore.addPlayerToTable(openId)}
          disabled={isPending}
          avatar={<AddIcon fontSize="small" />}
          classes={{
            root: classes.chip_root,
            avatarColorSecondary: classes.chip_avatarColorSecondary,
          }}
        />
      ),
    ]);

    return (
      <Paper
        elevation={0}
        className={clsx(classes.paper, {
          [classes.user]: ghost.id === table.game.me.id,
        })}
        data-testid={`ghost_${ghost.name}`}
      >
        <Box className={classes.paperLeft}>{!openId ? ghost.name : ''}</Box>
        <Box className={classes.paperRight}>
          {!!ghost.stack && (
            <Fade in={!openId}>
              <Typography variant="body2" className={classes.stack}>
                {ghost.stack}
              </Typography>
            </Fade>
          )}
          {!!ghostActions.length && (
            // @ts-ignore SpeedDial
            <SpeedDial
              actions={ghostActions}
              isOpen={openId === ghost.id}
              onClick={() =>
                openId === ghost.id ? setOpenId('') : setOpenId(ghost.id)
              }
              onClickAway={() => {
                openId === ghost.id && setOpenId('');
              }}
              OpenIcon={AddIcon}
            />
          )}
        </Box>
      </Paper>
    );
  }
);

export default Ghost;
