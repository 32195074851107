import { makeAutoObservable, runInAction } from 'mobx';

import { ITable } from '../interfaces';
import { getTableConnection } from '../services/api';

import TableStore from './TableStore';

export default class WebSocketStore {
  tableStore: TableStore;
  webSocket: WebSocket | null;
  isConnecting;
  isConnected;
  isWaiting;
  lastMessage: { data: string } | null;
  connectionAttempt;
  private shouldReconnect;
  private tableId?: string;
  private userId?: string;
  _timer?: ReturnType<typeof setTimeout>;

  constructor({ tableStore }: { tableStore: TableStore }) {
    makeAutoObservable(this);
    this.tableStore = tableStore;
    this.connectionAttempt = 0;
    this.isConnecting = false;
    this.isConnected = false;
    this.isWaiting = false;
    this.lastMessage = null;
    this.shouldReconnect = false;
    this.webSocket = null;
  }

  _hook = (ws: WebSocket) => {
    ws.onopen = () => this._onConnectionOpen();
    ws.onerror = () => this._onConnectionError();
    ws.onmessage = (message) => this._onMessage(message);
    ws.onclose = () => this._onConnectionClose();
    this.webSocket = ws;
  };

  connect = ({ tableId, userId }: Record<string, string>) => {
    runInAction(() => {
      this.tableId = tableId;
      this.userId = userId;
      this.isConnecting = true;
      this.isWaiting = false;
    });
    try {
      const webSocket = getTableConnection({ tableId, userId });
      this._hook(webSocket);
      this.shouldReconnect = true;
    } catch (err) {
      this.disconnect(500, 'error connecting to table');
    }
  };

  disconnect = (code?: number, reason?: string) => {
    runInAction(() => {
      this.shouldReconnect = false;
    });
    this.webSocket?.close(code, reason);
  };

  _onConnectionOpen = () => {
    this.isConnecting = false;
    this.isConnected = true;
  };

  _onConnectionError = () => {
    this.isConnecting = false;
    this.isConnected = false;
  };

  _onConnectionClose = () => {
    runInAction(() => {
      this.isConnecting = false;
      this.isConnected = false;

      if (this.shouldReconnect && !!this.tableId && !!this.userId) {
        !!this._timer && clearTimeout(this._timer);
        this.connectionAttempt++;
        this._timer = setTimeout(() => {
          runInAction(() => {
            this.connect({ tableId: this.tableId!, userId: this.userId! });
          });
        }, 1000 + this.connectionAttempt * 1000);
      }
    });
  };

  _onMessage = (message: { data: string }) => {
    this.lastMessage = message;
    const table: ITable = JSON.parse(message.data);
    this.tableStore.tableFromAPI = table;
  };

  _sendMessage = (data: any) => {
    this.webSocket?.send(JSON.stringify(data));
  };
}
