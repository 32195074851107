import React, { FunctionComponent, SVGProps } from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import isEqual from 'react-fast-compare';

import { ReactComponent as Club } from '../../assets/img/suitClub.svg';
import { ReactComponent as Diamond } from '../../assets/img/suitDiamond.svg';
import { ReactComponent as Heart } from '../../assets/img/suitHeart.svg';
import { ReactComponent as Spade } from '../../assets/img/suitSpade.svg';
import { ESuit } from '../../interfaces';
import { useColorscheme , colorschemes } from '../../services/useColorscheme';


const useTabStyles = makeStyles((theme) => {
  return {
    highlightedButton: {
      filter: 'brightness(0.9)',
      background: '#00000015',
      transition: 'filter 0.3s ease',
    },
  };
});

const useSuitSampleStyles = makeStyles((theme) => {
  return {
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '45% 45%',
      gridRow: 'auto auto',
      gridColumnGap: '5%',
      gridRowGap: '5%',
    },
  };
});

const suitIcons: {
  id: ESuit;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}[] = [
  { id: 'spades', Icon: Spade },
  { id: 'hearts', Icon: Heart },
  { id: 'diamonds', Icon: Diamond },
  { id: 'clubs', Icon: Club },
];

function SuitSample({ colorscheme }: { colorscheme: Record<ESuit, string> }) {
  const classes = useSuitSampleStyles();
  return (
    <div className={classes.wrapper}>
      {suitIcons.map(({ id, Icon }) => (
        <Icon key={id} style={{ color: colorscheme[id] }} />
      ))}
    </div>
  );
}

function SuitColorsTab({ className }: { className?: string }) {
  const classes = useTabStyles();
  const { colorscheme, setColorscheme } = useColorscheme();
  return (
    <ButtonGroup variant="outlined" fullWidth className={className}>
      {colorschemes.map(({ id, values, label }) => (
        <Button
          key={id}
          className={clsx(
            isEqual(values, colorscheme) && classes.highlightedButton
          )}
          disabled={isEqual(values, colorscheme)}
          startIcon={<SuitSample colorscheme={values} />}
          onClick={() => setColorscheme(id)}
        ></Button>
      ))}
    </ButtonGroup>
  );
}

export default SuitColorsTab;
