import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import { IPlayer } from '../../interfaces';
import useDragScroll from '../../services/useDragScroll';
import Badges from '../Badges';
import Tab from '../Tab';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      zIndex: 2,
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      marginBottom: -1,
    },
    tab: {
      '&$betting': {
        border: `1px solid ${colors.secondary}`,
        borderBottom: 'none',
        '&:after': {
          border: `1px solid ${colors.secondary}`,
          borderTop: 'none',
          borderRight: 'none',
        },
      },
      '&$victor': {
        border: `1px solid ${colors.gold}`,
        borderBottom: 'none',
        background: '#FDFACA',
        '&:after': {
          border: `1px solid ${colors.gold}`,
          borderTop: 'none',
          borderRight: 'none',
        },
      },
      '&$retired': {
        background: colors.grayLight,
      },
      '&$folded': {
        background: colors.grayLight,
      },
    },
    betting: {},
    folded: {},
    retired: {},
    victor: {},
    name: {
      display: 'flex',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      flexShrink: 1,
    },
    badgeWrapper: {
      display: 'flex',
      flexShrink: 0,
    },
    badges: {
      '& > *': {
        marginLeft: 4,
        '&:last-of-type': {
          marginRight: 8,
        },
      },
    },
  };
});

const HandTopElements = observer(
  ({
    player,
    isGameActive,
    className,
  }: {
    isGameActive: boolean;
    player: IPlayer;
    className?: string;
  }) => {
    const victorIds = useContext(TableContext)!.victorIds;
    const dragProps = useDragScroll();
    const status = player.status;

    function getTabBackgroundColor() {
      if (victorIds?.includes(player.id)) {
        return '#FDFACA';
      } else if (['RETIRED', 'FOLDED'].includes(status)) {
        return colors.grayLight;
      } else {
        return '#fff';
      }
    }
    const backgroundColor = getTabBackgroundColor();
    const classes = useStyles();
    return (
      <Box className={clsx(classes.root, className)} {...dragProps}>
        <Tab
          className={clsx(
            classes.tab,
            { [classes.betting]: isGameActive && status === 'BETTING' },
            { [classes.folded]: status === 'FOLDED' },
            {
              [classes.retired]: ['RETIRED', 'INACTIVE'].includes(
                player.status
              ),
            },
            { [classes.victor]: victorIds?.includes(player.id) }
          )}
          backgroundColor={backgroundColor}
          isFirst
        >
          <Typography variant="body2" className={classes.name}>
            {player.name}
          </Typography>
        </Tab>
        <Box className={classes.badgeWrapper}>
          <Badges
            className={classes.badges}
            id={player.id}
            status={player.status}
          />
        </Box>
      </Box>
    );
  }
);

export default HandTopElements;
