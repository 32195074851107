import React, { useEffect, ReactNode } from 'react';

import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import {
  configure as configureMobX,
  autorun,
  reaction,
  runInAction,
} from 'mobx';
import { observer } from 'mobx-react-lite';

import { TableContext } from '../../contexts/TableContext';
import { ITable } from '../../interfaces';
import {
  getActivePlayerIds,
  getAdminName,
  getBettingId,
  getHasAdd,
  getIds,
  getIsPlayerActive,
  getLargestStack,
  getLargestWager,
  getMaxStackAndWager,
  mergeDeepChanges,
} from '../../services/tableStateHelpers';
import TableStore from '../../stores/TableStore';
import WebSocketStore from '../../stores/WebSocketStore';

process.env.REACT_APP_ENV !== 'production' &&
  configureMobX({
    enforceActions: 'observed',
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    observableRequiresReaction: true,
    disableErrorBoundaries: true,
  });

const tableStore = new TableStore();
const webSocketStore = new WebSocketStore({ tableStore });

reaction(
  () => tableStore.tableFromAPI,
  (table?: ITable) => {
    if (table) {
      tableStore.table = runInAction(() =>
        mergeDeepChanges(tableStore.table, {
          ...table,
          game: omit(table.game, 'players', 'specators', 'ghosts'),
        })
      );
      tableStore.ghostIds = runInAction(() =>
        getIds(table.game.ghosts, tableStore.ghostIds)
      );
      tableStore.spectatorIds = runInAction(() =>
        getIds(table.game.spectators, tableStore.spectatorIds)
      );
      tableStore.victorIds = runInAction(() =>
        getIds(table.game.victors, tableStore.victorIds)
      );

      const playerIdTuple = runInAction(() =>
        getActivePlayerIds(
          table,
          tableStore.activePlayerIds,
          tableStore.inactivePlayerIds
        )
      );
      tableStore.activePlayerIds = runInAction(() => playerIdTuple[0]);
      tableStore.inactivePlayerIds = runInAction(() => playerIdTuple[1]);
      tableStore.bettingId = runInAction(() =>
        getBettingId(table, tableStore.bettingId)
      );
      tableStore.hands = runInAction(() =>
        mergeDeepChanges(
          tableStore.hands,
          mapValues(keyBy(table.game.players, 'id'), (p) =>
            p.hand.length
              ? {
                  0: p.hand?.[0],
                  1: p.hand?.[1],
                }
              : { 0: undefined, 1: undefined }
          )
        )
      );
      tableStore.userHand = runInAction(() =>
        mergeDeepChanges(
          tableStore.userHand,
          table.game.me.hand.reduce(
            (acc, curr, i) => ({ ...acc, [i]: curr }),
            {}
          )
        )
      );
      tableStore.communityCards = runInAction(() =>
        mergeDeepChanges(
          tableStore.communityCards,
          table.game.community_cards.reduce(
            (acc, curr, i) => ({ ...acc, [i]: curr }),
            {}
          )
        )
      );
      tableStore.players = runInAction(() =>
        mergeDeepChanges(tableStore.players, keyBy(table.game.players, 'id'))
      );

      tableStore.spectators = runInAction(() =>
        mergeDeepChanges(
          tableStore.spectators,
          keyBy(table.game.spectators, 'id')
        )
      );
      tableStore.ghosts = runInAction(() =>
        mergeDeepChanges(tableStore.ghosts, keyBy(table.game.ghosts, 'id'))
      );
      tableStore.victors = runInAction(() =>
        mergeDeepChanges(tableStore.victors, keyBy(table.game.victors, 'id'))
      );

      tableStore.adminName = runInAction(() =>
        getAdminName(table, tableStore.adminName)
      );
      tableStore.hasAdd = runInAction(() =>
        getHasAdd(table, tableStore.hasAdd)
      );
      tableStore.isPlayerActive = runInAction(() => getIsPlayerActive(table));
      tableStore.largestStack = runInAction(() =>
        getLargestStack(table, tableStore.largestStack)
      );
      tableStore.largestWager = runInAction(() =>
        getLargestWager(table, tableStore.largestWager)
      );
      tableStore.maxStackAndWager = runInAction(() =>
        getMaxStackAndWager(table, tableStore.maxStackAndWager)
      );
    }
  }
);

export const TableContextProvider = observer(
  ({
    children,
    tableId,
    userId,
    onClose,
    onSuccess,
  }: {
    children: ({
      isConnected,
      isConnecting,
      tableStore,
    }: {
      isConnected: boolean;
      isConnecting: boolean;
      tableStore: TableStore;
    }) => ReactNode;
    tableId: string;
    userId: string;
    onClose: () => void;
    onSuccess: () => void;
  }) => {
    useEffect(() => {
      autorun(() => {
        if (
          !webSocketStore.isConnecting &&
          !webSocketStore.isConnected &&
          !webSocketStore.connectionAttempt
        ) {
          webSocketStore.connect({ tableId, userId });
        }
      });
    }, [tableId, userId]);

    useEffect(() => {
      autorun(() => {
        if (webSocketStore.isConnected || webSocketStore.isConnecting) {
          return () => webSocketStore.disconnect();
        }
      });
    }, []);

    return (
      <TableContext.Provider value={tableStore}>
        {children({
          isConnected: !!webSocketStore.isConnected,
          isConnecting: !!webSocketStore.isConnecting,
          tableStore,
        })}
      </TableContext.Provider>
    );
  }
);
