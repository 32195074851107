import { TSidePot } from '../interfaces';

export default function getSidePots(side_pot: TSidePot | null) {
  function go(
    { players, pot, victors, side_pot }: TSidePot,
    acc: Omit<TSidePot, 'side_pot'>[]
  ): Omit<TSidePot, 'side_pot'>[] {
    const sidePots = [...acc, { pot, players, victors }];
    if (!side_pot) {
      return sidePots;
    } else {
      return go(side_pot, sidePots);
    }
  }
  if (!!side_pot) {
    return go(side_pot, []);
  } else {
    return [];
  }
}
