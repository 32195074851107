import React, {
  useState,
  useEffect,
  ReactElement,
  FunctionComponent,
  SVGProps,
  useContext,
} from 'react';

import Box from '@material-ui/core/Box';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { observer } from 'mobx-react-lite';
import isEqual from 'react-fast-compare';

import { ReactComponent as BlueCardBack } from '../../assets/cards/BLUE_BACK.svg';
import { TableContext } from '../../contexts/TableContext';
import { TCard, ESuit } from '../../interfaces';
import { useColorscheme } from '../../services/useColorscheme';

export type TCardSize = 'small' | 'medium' | 'large' | 'xl';

function getWidth(size: TCardSize) {
  switch (size) {
    case 'small':
    default:
      return 24;
    case 'medium':
      return 40;
    case 'large':
      return 88;
    case 'xl':
      return 120;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  card: {
    height: ({ size }: { size: TCardSize }) => getWidth(size) * 1.396827459,
    width: ({ size }: { size: TCardSize }) => getWidth(size),
    borderRadius: ({ size }: { size: TCardSize }) => getWidth(size) / 20,
    userSelect: 'none',
    '&:not(:first-child)': {
      marginLeft: 4,
    },
  },
  hasBoxShadow: {
    boxShadow: '-1px 1px 1px 0px rgba(0,0,0,0.18)',
  },
  highlighted: {
    filter: `drop-shadow(0px 0px 3px #FFF49ECC)
    drop-shadow(0px 0px 5px rgba(255,255,255,0.5))
    drop-shadow(0px 0px 7px rgba(255,255,255,1))`,
  },
  unhighlighted: {
    filter: 'saturate(0.5) contrast(0.5) brightness(115%)',
  },
  isHand: {
    '&:not(:first-child)': {
      marginLeft: ({ size }: { size: TCardSize }) => -getWidth(size) * 0.5,
    },
  },
}));

function SlideHandler({
  children,
  hasSlide,
  ...props
}: {
  children: ReactElement;
  hasSlide: boolean;
} & SlideProps) {
  if (hasSlide) {
    return <Slide {...props}>{children}</Slide>;
  } else return children;
}

const CardsWrapper = observer(
  ({
    cards,
    playerId,
    ...props
  }: {
    cards?: Record<number, TCard | undefined>;
    playerId?: string;
  } & Omit<Parameters<typeof Cards>[0], 'cards' | 'isHand'> & {
      isHand?: boolean;
    }) => {
    if (cards) {
      return <Cards cards={cards} {...props} isHand={props.isHand || false} />;
    } else if (!!playerId) {
      return <Hand playerId={playerId} {...props} isHand={true} />;
    } else {
      return <CommunityCards {...props} isHand={false} />;
    }
  }
);

const CommunityCards = observer(
  (props: Omit<Parameters<typeof Cards>[0], 'cards'>) => {
    const tableStore = useContext(TableContext)!;
    const communityCards = tableStore.communityCards || {};

    return <HighlightableCards cards={communityCards} {...props} />;
  }
);

const Hand = observer(
  ({
    playerId,
    ...props
  }: { playerId: string } & Omit<Parameters<typeof Cards>[0], 'cards'>) => {
    const tableStore = useContext(TableContext)!;
    const isUser = playerId === tableStore.table!.game.me.id;

    return isUser ? (
      <UserCards {...props} />
    ) : (
      <PlayerCards playerId={playerId} {...props} />
    );
  }
);

const UserCards = observer(
  (props: Omit<Parameters<typeof Cards>[0], 'cards'>) => {
    const tableStore = useContext(TableContext)!;
    return <HighlightableCards cards={tableStore.userHand!} {...props} />;
  }
);

const PlayerCards = observer(
  ({
    playerId,
    ...props
  }: { playerId: string } & Omit<
    Parameters<typeof HighlightableCards>[0],
    'cards'
  >) => {
    const tableStore = useContext(TableContext)!;
    const hand = tableStore.hands![playerId];
    return <HighlightableCards id={playerId} cards={hand} {...props} />;
  }
);

const HighlightableCards = observer(
  ({
    id,
    cards,
    highlightIndices: passedHighlightIndices,
    ...props
  }: { id?: string } & Parameters<typeof Cards>[0]) => {
    const tableStore = useContext(TableContext)!;
    const victorIds = tableStore.victorIds;
    const victorCards = id
      ? tableStore.victors![id]?.hand || []
      : uniq(flatMap(victorIds, (id) => tableStore.victors![id].hand));
    const highlightIndices =
      passedHighlightIndices ||
      map(cards).reduce(
        (acc, card, i) =>
          victorCards.find((c) => isEqual(c, card)) ? [...acc, i] : acc,
        [] as number[]
      );

    return (
      <Cards
        cards={cards}
        highlightIndices={victorIds?.length ? highlightIndices : undefined}
        {...props}
      />
    );
  }
);

const Cards = observer(
  ({
    cards = {},
    cardClassName,
    className,
    disableElevation = true,
    highlightIndices,
    isHand = false,
    size = 'small',
  }: {
    cards: Record<number, TCard | undefined>;
    cardClassName?: string;
    className?: string;
    disableElevation?: boolean;
    highlightIndices?: number[];
    isHand: boolean;
    size?: TCardSize;
  }) => {
    const { colorscheme } = useColorscheme();
    const classes = useStyles({ size });

    const [cardMap, setCardMap] = useState<Record<
      string,
      FunctionComponent<SVGProps<SVGSVGElement>>
    > | null>(null);
    useEffect(
      function importCardMap() {
        if (!cardMap) {
          import('../../constants/cardMap').then((cardMap) => {
            setCardMap(cardMap.default);
          });
        }
      },
      [cardMap, setCardMap]
    );

    const cardsArray = isHand ? Array(2).fill(null) : Array(5).fill(null);

    function getSuitColor(suit: ESuit | undefined) {
      if (!suit) return '';
      return colorscheme?.[suit] || '';
    }

    const cardComponents = compact(
      cardsArray.map((_, i) => {
        if (cards[i] && !!cardMap) {
          const card = cards[i]!;
          return cardMap[
            `${card.rank.toUpperCase()}${card.suit[0].toUpperCase()}`
          ];
        } else if (isHand) {
          return BlueCardBack;
        } else {
          return null;
        }
      })
    );

    if (isHand) {
      return (
        <Box className={clsx(classes.root, className)}>
          {cardComponents.map((Card, i) => (
            <Card
              key={i}
              className={clsx(
                classes.card,
                classes.isHand,
                {
                  [classes.hasBoxShadow]: !disableElevation,
                  [classes.highlighted]:
                    highlightIndices && highlightIndices.includes(i),
                  [classes.unhighlighted]:
                    highlightIndices && !highlightIndices.includes(i),
                },

                cardClassName
              )}
              style={{
                color: getSuitColor(cards?.[i]?.suit),
                zIndex: i,
              }}
            />
          ))}
        </Box>
      );
    } else {
      return (
        <Box className={clsx(classes.root, className)}>
          {cardComponents.map((Card, i) => (
            <SlideHandler
              hasSlide={!isHand}
              direction="down"
              key={i}
              in={i + 1 <= compact(Object.values(cards)).length}
            >
              {Card ? (
                <Card
                  className={clsx(
                    classes.card,
                    {
                      [classes.highlighted]:
                        highlightIndices && highlightIndices.includes(i),
                      [classes.unhighlighted]:
                        highlightIndices && !highlightIndices.includes(i),
                    },
                    cardClassName
                  )}
                  style={{
                    color: getSuitColor(cards?.[i]?.suit),
                    zIndex: i,
                  }}
                />
              ) : (
                <div />
              )}
            </SlideHandler>
          ))}
        </Box>
      );
    }
  }
);

export default CardsWrapper;
