import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { TableContext } from '../../contexts/TableContext';
import Cards from '../Cards';


const useStyles = makeStyles((theme) => {
  return {
    paper: {
      width: '100%',
      backgroundColor: colors.secondaryMuted,
      display: 'flex',
      justifyContent: 'center',
      padding: 8,
      minHeight: 138,
      overflow: 'hidden',
      transition: 'background-color 0.4s ease-in-out',
      '&$victory': {
        backgroundColor: 'rgb(252,246,186)',
        background: `linear-gradient(
            156deg, 
            rgba(252,246,186,1) 9%, 
            rgba(247,236,172,1) 18%, 
            rgba(247,232,148,1) 20%, 
            rgba(255,235,120,1) 32%, 
            rgba(246,231,85,1) 39%, 
            rgba(233,212,77,1) 48%, 
            rgba(255,238,163,1) 61%, 
            rgba(251,245,183,1) 66%, 
            rgba(245,232,118,1) 79%, 
            rgba(241,235,177,1) 97%
          )`,
        '&:after': {
          animation: '$shine 5s ease-in-out infinite',
          animationFillMode: 'forwards',
          content: '""',
          position: 'absolute',
          zIndex: 100,
          top: '-110%',
          left: '-210%',
          width: '100%',
          height: '400%',
          opacity: 0,
          background: `linear-gradient(
                to right,
                rgba(255,255,255,0.13) 0%,
                rgba(255,255,255,0.13) 77%,
                rgba(255,255,255,0.5) 92%, 
                rgba(255,255,255,0.0) 100%
              )`,
          transform: 'rotate(30deg)',
          pointerEvents: 'none',
        },
      },
    },
    '@keyframes shine': {
      '10%': {
        opacity: '1',
        top: '-160%',
        left: '30%',
        transitionProperty: 'left,top,opacity',
        transitionDuration: '0.9s,0.9s,0.12s',
        transitionTimingFunction: 'ease',
      },
      '100%': {
        opacity: '0',
        top: '-160%',
        left: '30%',
        transitionProperty: 'left,top,opacity',
      },
    },
    victory: {},
    wrapper: {
      position: 'relative',
      width: '90%',
      maxWidth: 480,
      zIndex: 2,
      transition: 'transform 0.2s ease',
    },
    cards: {
      position: 'relative',
      zIndex: 2,
      height: 123,
      margin: '0 auto',
    },
    card: {
      position: 'absolute',
      '&:first-of-type': {
        marginLeft: -44,
        left: '10%',
      },
      '&:nth-of-type(2)': {
        marginLeft: -44,
        left: '30%',
      },
      '&:nth-of-type(3)': {
        marginLeft: -44,
        left: '50%',
      },
      '&:nth-of-type(4)': {
        marginLeft: -44,
        left: '70%',
      },
      '&:nth-of-type(5)': {
        marginLeft: -44,
        left: '90%',
      },
    },
    markers: {
      position: 'absolute',
      display: 'flex',
      margin: '0 4px',
      zIndex: 1,
      top: 5,
      left: 0,
      opacity: 0.3,
    },
    marker: {
      border: `1px solid ${colors.tertiary}`,
      borderRadius: 5,
      background: colors.light,
      width: 48,
      height: 68,
      '&:not(:last-child)': {
        marginRight: 12,
      },
    },
  };
});

const CommunityCards = observer(
  ({
    className,
    wrapperClassName,
  }: {
    className?: string;
    wrapperClassName?: string;
  }) => {
    const classes = useStyles();
    const tableStore = useContext(TableContext)!;
    const victoryIds = tableStore.victorIds;
    const userId = tableStore.table!.game.me.id;

    return (
      <Paper
        elevation={0}
        square
        className={clsx(
          classes.paper,
          className,
          victoryIds?.includes(userId) && classes.victory
        )}
      >
        <Box className={clsx(classes.wrapper, wrapperClassName)}>
          <Cards
            size="large"
            className={classes.cards}
            cardClassName={classes.card}
          />
        </Box>
      </Paper>
    );
  }
);

export default CommunityCards;
