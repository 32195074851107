import { AxiosPromise } from 'axios';

export default function destructureAxiosReq<T = any>(
  axiosPromise: AxiosPromise<T>
): Promise<T> {
  return new Promise((resolve, reject) => {
    axiosPromise.then(
      (response) => resolve(response.data),
      (error) => {
        reject(error);
      }
    );
  });
}
